import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ImgUrl, addDefaultSrc } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { NoteDiv } from "../styledComponents/notesStyles";
import GLightBox from "../gComponents/gLightBox";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const idStyle = {
  float: "right",
  fontSize: "18px",
  color: "#2d4961",
  margin: "5px",
  fontWEight: "400",
  fontStyle: "italic",
};
const imgStyle = {
  cursor: "pointer",
  width: "80px",
  border: "1px solid #e2e2e2",
  padding: "5px",
};

export default function OrderNotes(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [signatures, setSigns] = useState([]);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [selectedImgType, setSelectedImgType] = useState("");

  useEffect(() => {
    setNotes(props.notes || []);
    setImages(props.images || []);
    setSigns(props.signatures || []);
  }, [props]);

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setOdInfovisible(false);
  };

  if (props.odInfovisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.odInfovisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.odInfovisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>
            Delivery Info
            <p style={idStyle}> #{props.linkedId}</p>
          </h3>
          <div
            style={{
              padding: "5px 20px 15px",
              marginBottom: "20px",
            }}
          >
            <h4>Notes ({notes.length}) </h4>

            {notes.map((p) => {
              return (
                <div key={p.id}>
                  <NoteDiv className={"send"}>
                    <h5>{p.notes}</h5>
                    <p>{p.created}</p>
                  </NoteDiv>
                </div>
              );
            })}
            {notes.length === 0 ? (
              <p style={{ color: "#b2b2b2", textAlign: "center" }}>
                No notes found
              </p>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div
            style={{
              padding: "5px 10px 15px",
              marginBottom: "20px",
            }}
          >
            <h4>Images ({images.length}) </h4>

            {images.map((p, i) => {
              return (
                <div
                  key={p.id}
                  style={{ display: "inline-block", margin: "5px 10px" }}
                >
                  <img
                    alt=""
                    src={ImgUrl("dimage") + "/" + p.image_path}
                    onError={addDefaultSrc}
                    className="thumbnail"
                    style={imgStyle}
                    onClick={() => {
                      setSelectedImgPreview(p.image_path);
                      setImgPreview(true);
                      setSelectedImgType("dimage");
                    }}
                  />
                </div>
              );
            })}

            {images.length === 0 ? (
              <p style={{ color: "#b2b2b2", textAlign: "center" }}>
                No images found
              </p>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div
            style={{
              padding: "5px 10px 15px",
              marginBottom: "20px",
            }}
          >
            <h4>Signatures ({signatures.length}) </h4>

            {signatures.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ display: "inline-block", margin: "5px 10px" }}
                >
                  <img
                    alt=""
                    src={ImgUrl("dsignature") + "/" + p.signature_path}
                    onError={addDefaultSrc}
                    className="thumbnail"
                    style={imgStyle}
                    onClick={() => {
                      setSelectedImgPreview(p.signature_path);
                      setImgPreview(true);
                      setSelectedImgType("dsignature");
                    }}
                  />
                </div>
              );
            })}

            {signatures.length === 0 ? (
              <p style={{ color: "#b2b2b2", textAlign: "center" }}>
                No signatures found
              </p>
            ) : (
              ""
            )}
          </div>
        </Paper>

        {isImgPreview === true ? (
          <GLightBox
            images={selectedImgPreview}
            isImgPreview={isImgPreview}
            setImgPreview={setImgPreview}
            imgType={selectedImgType}
          ></GLightBox>
        ) : (
          ""
        )}

        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
