/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import PricingRulePopup from "../components/pricingRulePopup";
import GButton from "../gComponents/gButton";
import GPricingRuleCard from "../gComponents/gPricingRuleOptions";
import "../css/multiselect.css";
import Modal from "react-modal";

export default function VehicleTypeForm(props) {
  const [prsvisible, setPrsvisible] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [selectedRule, setSelectedRule] = useState();
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    pricing_rule_id: !props.isEmpty ? props.data.pricing_rule_id : "",
    weight: !props.isEmpty ? props.data.weight : "",
    volume: !props.isEmpty ? props.data.volume : "",
    photo: !props.isEmpty ? props.data.photo : "",
    thumb_photo: !props.isEmpty ? props.data.thumb_photo : "",
  };

  useEffect(() => {
    setSelectedRule(!props.isEmpty ? props.data.rule : "");
  }, [props]);

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    values.pricing_rule_id = selectedRule.id;
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "vehicle/types/" + props.data.id
      : "vehicle/types";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setVehvisible(false);
          props.onSubmit();
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    volume: Yup.string().required("Required"),
    weight: Yup.string().required("Required"),
  });

  if (props.vehvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Vehicle Type
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            //  function updateField(newValue){
            //      values.icon = newValue
            // }

            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.photo = newValue;
                  values.thumb_photo = thumb_val;
                }}
                id="thumb_image"
                image={
                  values.thumb_photo
                    ? ImgUrl("vehicle_type") + "/" + values.thumb_photo
                    : ""
                }
                type="vehicle_type"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />
              <br />
              <FormikControl
                control="input"
                type="text"
                label="Title"
                name="title"
              />

              <div style={{ display: "flex" }}>
                <FormikControl
                  control="input"
                  type="text"
                  label="Weight"
                  name="weight"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("weight", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Volume"
                  name="volume"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("volume", event.target.value);
                    }
                  }}
                />
              </div>

              <div style={{ margin: "15px" }}>
                <label>
                  Select Pricing Rule &nbsp;&nbsp;
                  <a
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPrsvisible(true);
                    }}
                  >
                    Change
                  </a>
                </label>

                {selectedRule ? (
                  <div
                    style={{
                      background: "#f4f5f9",
                      padding: "10px",
                      width: "50%",
                      marginTop: "5px",
                    }}
                  >
                    <h4
                      style={{
                        margin: "3px 0",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {selectedRule.title}
                    </h4>
                    <GPricingRuleCard ruleData={selectedRule} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <br />

              <GButton
                variant="condensed"
                disabled={isBtnDisabled}
                type="submit"
              >
                {!props.isEmpty ? "Update" : "Create"}
              </GButton>
            </Form>
          )}
        </Formik>
      </div>

      <Modal
        isOpen={prsvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPrsvisible(false)}
        style={{
          content: {
            width: "588px",
          },
        }}
        contentLabel="Pricing Rules Modal"
      >
        <PricingRulePopup
          selectedRule={selectedRule}
          prsvisible={prsvisible}
          setPrsvisible={setPrsvisible}
          onSubmit={(rule) => {
            console.log(rule);
            setSelectedRule(rule);
          }}
        />
      </Modal>
    </>
  );
}
