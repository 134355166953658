import React, { useRef, useState } from "react";
import styled from "styled-components";
import GDropdown from "./gDropdown";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "./gButton";
import GInfo from "./gInfo";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import { AddCircleOutline } from "@material-ui/icons";
import AssignDriverPopup from "../components/assignDriver";
import AssigningIcon from "../assets/images/wall-clock-white.svg";
import { ApiService } from "../services";

const AgentDiv = styled.div`
  margin-bottom: 10px;
  small {
    color: #777;
    font-style: italic;
    display: block;
    padding: 5px 10px;
  }
`;

const assignList = [
  {
    title: "Assign Manually",
    value: "manually",
  },
  {
    title: "Assign Automatically",
    value: "automatically",
  },
];

export default function GCancelBox(props) {
  const [advisible, setAdvisible] = useState(false);
  const AssignDropdownRef = useRef(null);
  const [openSnackbar] = useSnackbar();
  const [isDropdownActive, setIsDropdownActive] = useDetectOutsideClick(
    AssignDropdownRef,
    false
  );

  const handleSelectValue = (selectedValue) => {
    if (selectedValue === "manually") {
      setAdvisible(true);
    } else {
      ApiService({
        method: "POST",
        route: "order/task/auto-assign/" + props.orderId,
      }).then((response) => {
        console.log(response.data);
        if (response.data?.status_code == 0) {
          openSnackbar(response.data?.message);
        } else {
          props.onAssignTask();
          openSnackbar(
            response.message || "Request sent to the driver successfully"
          );
        }
      });
    }
  };

  return (
    <AgentDiv>
      {props.isAssign === false ? (
        <React.Fragment>
          <GButton
            disabled={props.orderStatus === "Cancelled"}
            variant="linkable"
            onClick={() => {
              setIsDropdownActive(true);
            }}
            style={{
              color: props.orderStatus === "Cancelled" ? "#a2a2a2" : "#2d4961",
            }}
          >
            <AddCircleOutline style={{ verticalAlign: "middle" }} /> Assign
            Driver
          </GButton>

          {isDropdownActive === true ? (
            <div ref={AssignDropdownRef}>
              <GDropdown
                id="assign"
                className={`menu ${isDropdownActive ? "active" : "inactive"}`}
                listData={assignList}
                onSelectValue={(value) => {
                  setIsDropdownActive(false);
                  handleSelectValue(value);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {props.isViewInfo !== false && <small>No driver assigned yet</small>}
        </React.Fragment>
      ) : props.taskLog ? (
        <React.Fragment>
          <p
            style={{
              color: "#fff",
              fontStyle: "italic",
              background: "#2d4961",
              padding: "5px",
              display: "inline-block",
              fontWeight: "550",
              margin: "10px 0 15px",
              fontSize: "14px",
            }}
          >
            <img
              src={AssigningIcon}
              alt="Task"
              style={{ width: "20px", verticalAlign: "middle" }}
            />{" "}
            &nbsp;&nbsp;Assigning ({props.taskLog.current_count} of{" "}
            {props.taskLog.retries_count}) - {props.taskLog.expire_time}
            sec each
          </p>
          <GInfo
            title={props.driverData.title + " (current)"}
            key={props.driverData.id}
            subtitle={props.driverData.phone}
            photo={props.driverData.thumb_photo}
            imgType="user"
            link={"/driver/" + props.driverData.id + "/orders"}
            linkState="driver"
          />
        </React.Fragment>
      ) : (
        <>
          {props.isViewInfo !== false && (
            <GInfo
              title={props.driverData.title}
              key={props.driverData.id}
              subtitle={props.driverData.phone}
              photo={props.driverData.thumb_photo}
              imgType="user"
              link={"/driver/" + props.driverData.id + "/orders"}
              linkState="driver"
            />
          )}
        </>
      )}
      {props.isReAssign === true &&
      props.isAssign !== false &&
      !props.taskLog ? (
        <>
          <GButton
            disabled={props.orderStatus === "Cancelled"}
            variant="linkable"
            onClick={() => {
              setIsDropdownActive(true);
            }}
            style={{
              color: props.orderStatus === "Cancelled" ? "#a2a2a2" : "#2d4961",
              marginTop: "5px",
            }}
          >
            <AddCircleOutline style={{ verticalAlign: "middle" }} /> Re-Assign
            Driver
          </GButton>
          {isDropdownActive === true ? (
            <div ref={AssignDropdownRef}>
              <GDropdown
                id="assign"
                className={`menu ${isDropdownActive ? "active" : "inactive"}`}
                listData={assignList}
                onSelectValue={(value) => {
                  setIsDropdownActive(false);
                  handleSelectValue(value);
                }}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {advisible === true ? (
        <AssignDriverPopup
          advisible={advisible}
          setAdvisible={setAdvisible}
          linkedId={props.orderId}
          vehicleTypeId={props?.vehicleTypeId || ""}
          vehicleTypeTitle={props?.vehicleTypeTitle || ""}
          onSubmit={() => props.onAssignTask()}
        />
      ) : (
        ""
      )}
    </AgentDiv>
  );
}
