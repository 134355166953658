import React, {useState, useEffect, useRef} from "react";
import {ApiService, ImgUrl, FileApiService} from "../services";
import {useParams, useHistory} from "react-router-dom";
import {MessageBox} from "../styledComponents/chatStyles";
import {Send, AttachFile} from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import {useSnackbar} from "react-simple-snackbar";
import GTableShimmer from "../gComponents/gTableShimmer";
import useDataFctory from "../useDataFactory";
import styled from "styled-components";
import GLightBox from "../gComponents/gLightBox";
import GText from "../gComponents/gText";
import Loader from "../assets/images/loader.svg";
import {createSocketConnection} from "../useLaravelPusher";
import ChatBackgroundImg from "../assets/images/placeholders/chat_background.png";

const DIV = styled.div`
  display: flex;
  align-items: flex-end;
  bottom: 0px;
  right: 0;
  left: 0px;
  position: absolute;
  padding: 10px;
  background: #f3f5f7;
  border: 1px solid #e9e9e9;
  z-index: 9;

  textarea {
    // border-radius: 8px;
    background: #fff;
    border: 0;
    padding: 0 0.6rem;
    font-size: 0.95rem;
    font-family: inherit;
    outline: 0;
    box-shadow: none;
    resize: none;
    flex: 1;
    max-height: 40px;
  }
`;

const OrderChat = props => {
  let history = useHistory();
  const fileInput = useRef(null);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [openSnackbar] = useSnackbar();
  const [messageVal, setMessageValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(!props.userId ? true : false);
  const {
    loading,
    Shimmer,
    Placeholder,
    data: messages,
    loadMore,
    pagination: messagesPagination,
    refreshData,
  } = useDataFctory("support_message", true, {
    user_id: props.userId,
    page: 1,
  });
  const [uploading, setUploading] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState();
  const [isImgPreview, setImgPreview] = useState(false);

  useEffect(() => {
    createSocketConnection();
  }, []);

  useEffect(() => {
    window.Echo.channel(`support-message.${props.userId}`).listen(
      "SupportMessageSent",
      function (e) {
        console.log(e);
        console.log(messages);
        if (messages && messages.length > 0) {
          let findIndex = messages.findIndex(m => m.id == e.message?.id);
          if (findIndex == -1) {
            messages.splice(messages, 0, e.message);
            scrollToBottom();
          }
        }
      }
    );
    return () => {
      window.Echo.leaveChannel(`support-message.${props.userId}`);
    };
  }, [messages]);

  useEffect(() => {
    console.log(props.userId);
    if (props.userId) {
      history.push({
        pathname: "/chat-support/" + props.userId,
        state: "support",
      });
      refreshData({user_id: props.userId, page: 1});
      setIsDisabled(false);
    }
  }, [props.userId]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({behaviour: "smooth", bottom: 0});
  };
  useEffect(() => {
    if (loading == false) {
      console.log(messagesPagination);
      if (messagesPagination?.current_page < 1 && messages.length > 0) {
        messagesEndRef.current.scrollIntoView({bottom: 0});
      }
    }
  }, [messagesPagination, loading, messages]);

  function updateField(mType, fileVal) {
    if (mType == "text" && !messageVal) {
      openSnackbar("Please enter a message");
      return;
    }
    setIsDisabled(true);
    var post_note = {
      user_type: "3",
      admin_id: 1,
      user_id: props.newDriver?.id || props.userId,
      message: mType == "image" ? fileVal : messageVal,
      message_type: mType,
    };
    ApiService({method: "POST", route: "support/messages", body: post_note})
      .then(response => {
        console.log(response);
        if (response.data.status_code === 1) {
          if (mType == "text") {
            setMessageValue("");
          }
          let findIndex = messages.findIndex(
            m => m.id == response.data.data?.id
          );
          if (findIndex == -1) {
            messages.splice(messages, 0, response.data.data);
            // messages.push(response.data.data);
            // messages = [...messages, response.data.data];
            props.onSendMessage();
            setTimeout(() => {
              scrollToBottom();
            }, 500);
          }
        } else {
          openSnackbar(response.data.message);
        }
        setIsDisabled(false);

        setUploading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }

  function enterPressed(event) {
    var code = event.keyCode || event.which;
    console.log(code);
    console.log(event.shiftKey);
    if (code === 13 && event.shiftKey) {
      //13 is the enter keycode
      //Do stuff in here
    } else if (code === 13 && !event.shiftKey) {
      event.preventDefault();
      if (isDisabled == false) {
        updateField("text");
      }
    }
  }

  const changeFileHandler = e => {
    setUploading(true);
    var files = fileInput.current.files[0];

    FileApiService("POST", "support", files)
      .then(response => {
        console.log(response);
        if (response.status_code === 1) {
          updateField("image", response.data.image);
          fileInput.current.value = "";
        } else {
          openSnackbar(response.message);

          setUploading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [messageVal]);

  return (
    <div
      style={{
        backgroundImage: `url(${ChatBackgroundImg})`,
        backgroundSize: "200px",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "10px 20px",
          borderBottom: "1px solid #e4e6ea",
        }}
      >
        <div style={{display: "flex", alignItems: "center"}}>
          <GText
            g3
            bold
            text={props.userInfo?.user_title || props.userInfo?.title}
            style={{margin: "5px 0"}}
          />
          <GButton
            variant="linkable"
            onClick={() => {
              window.open(
                process.env.REACT_APP_BASE_URL +
                  "/driver/" +
                  props.userId +
                  "/orders",
                "_blank"
              );
            }}
            style={{
              fontSize: "15px",
              textDecoration: "underline",
            }}
          >
            Detail
          </GButton>
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 192px)",
          padding: "0 30px 0 20px",
          overflow: "overlay",
        }}
      >
        {loading == true ? (
          <GTableShimmer />
        ) : (
          <>
            {messagesPagination.next_page_url && (
              <GButton
                variant="linkable"
                children="Load Older"
                type="button"
                onClick={() => loadMore()}
                style={{
                  display: "flex",
                  margin: "10px auto",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              />
            )}
            {messages
              .slice(0)
              .reverse()
              .map((p, i) => {
                return (
                  <div
                    key={i}
                    style={{textAlign: p.admin_id ? "right" : "left"}}
                  >
                    <MessageBox className={p.admin_id ? "send" : "received"}>
                      {p.message_type == "image" ? (
                        <img
                          src={ImgUrl("support") + "/" + p.message}
                          style={{
                            width: "200px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedImgPreview(p.message);
                            setImgPreview(true);
                          }}
                        />
                      ) : (
                        <h5
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {p.message}
                        </h5>
                      )}

                      <p
                        style={{
                          padding:
                            p.message_type == "image" ? "2px" : "2px 8px",
                        }}
                      >
                        {/* {p.admin_id ? "" : p.sender_title + ", "} */}
                        {p.created}
                      </p>
                    </MessageBox>
                  </div>
                );
              })}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <DIV>
        <input
          type="file"
          id="itemFile"
          ref={fileInput}
          onChange={changeFileHandler}
          style={{display: "none"}}
        />

        <div
          style={{
            // position: "absolute",
            // bottom: 0,
            // left: "auto",
            // right: 60,
            // height: "100%",
            // width: "calc(100% - 60px)",
            // justifyContent: "end",
            width: "100%",
            padding: "8px 15px",
            display: "flex",
            alignItems: "center",
            background: "#fff",
            borderRadius: "50px",
            gap: "10px",
          }}
        >
          <textarea
            rows={1}
            type="text"
            value={messageVal}
            id="messages"
            wrap="hard"
            placeholder="Type message here.."
            onChange={e => setMessageValue(e.target.value)}
            onKeyPress={enterPressed}
            ref={textareaRef}
          ></textarea>

          {uploading ? (
            <img
              src={Loader}
              alt="loading"
              style={{verticalAlign: "middle", width: "25px"}}
            />
          ) : (
            <GButton
              variant="linkable"
              disabled={uploading}
              onClick={() => {
                document.getElementById("itemFile").click();
              }}
              style={{
                height: "25px",
                transform: "rotate(45deg)",
                padding: 0,
              }}
            >
              <AttachFile style={{fontSize: "1.4rem"}} />
            </GButton>
          )}

          <GButton
            variant="linkable"
            disabled={isDisabled}
            onClick={() => {
              updateField("text");
            }}
            style={{
              borderRadius: "50%",
              color: "#fff",
              background: "#f7b500",
              height: "30px",
              padding: "3px 5px 0 7px",
            }}
          >
            <Send style={{fontSize: "1.2rem"}} />
          </GButton>
        </div>
      </DIV>

      {isImgPreview === true ? (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="support"
        ></GLightBox>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderChat;
