import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GMapingCircle from "../gComponents/gMapDrawingCircle";
import GButton from "../gComponents/gButton";
import GPolygon from "../leaflet/gPolygon";
import useDataFctory from "../useDataFactory";

const geoTypes = [
  { title: "Circle", value: "circle" },
  { title: "Polygon", value: "polygon" },
];

export default function GeofencePopup(props) {
  const [openSnackbar] = useSnackbar();
  const { data: citiesData } = useDataFctory("cities", false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedRdius, setSelectedRadius] = useState({
    radius: "",
    lat: "",
    lng: "",
  });

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    city_id: props.selectedCity || "",
    type: !props.isEmpty ? props.data.type : "polygon",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    if (values.type === "polygon") {
      values.polygon = selectedPolygons;
      if (values.polygon.length === 0) {
        openSnackbar("Polygon Area is required");
        setIsBtnDisabled(false);
        return;
      }
    }
    if (values.type === "circle") {
      values.circle = selectedRdius;
    }
    console.log(JSON.stringify(values));
    // return;

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "geofences/" + props.data.id : "geofences";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setGeofvisible(false);
          props.onSubmit(response.data);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
  });

  if (props.geofvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header" style={{ padding: "20px 25px" }}>
        Create Geofence
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                style={{
                  position: "absolute",
                  width: "150px",
                  right: "30px",
                  top: "17px",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label="Title"
                name="title"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              {citiesData ? (
                <FormikControl
                  control="select"
                  as="select"
                  label="City"
                  placeholder="Select city"
                  name="city_id"
                  options={citiesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              ) : (
                ""
              )}

              {/* <FormikControl
                  control="select"
                  as="select"
                  label="Type"
                  name="type"
                  placeholder="Select type"
                  options={geoTypes}
                  key_title="title"
                  key_value="value"
                /> */}

              <div style={{ margin: "15px" }}>
                <label>Area</label>
                {values.type === "polygon" ? (
                  // <GMaping
                  //   getGeoJSON={(json) => {
                  //     console.log(json);
                  //     setSelectedPolygons([
                  //       ...selectedPolygons,
                  //       json.geometry.coordinates[0],
                  //     ]);
                  //   }}
                  // />
                  <GPolygon
                    getGeoJSON={(jsonArray) => {
                      console.log(jsonArray);
                      setSelectedPolygons(jsonArray);
                    }}
                  />
                ) : values.type === "circle" ? (
                  <GMapingCircle
                    getRadiusValue={(rad, lat, lng) =>
                      setSelectedRadius({ radius: rad, lat: lat, lng: lng })
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
