import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { CheckCircleOutline, AccountBalanceWallet } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import GTableShimmer from "../gComponents/gTableShimmer";
import { useSnackbar } from "react-simple-snackbar";
import GPagination from "../gComponents/gPagination";
import useSettings from "../useSettings";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { DriverFilter } from "../components/driverFilterComponent";

const columns = [
  { id: "driver_name", label: "Driver Name", minWidth: 120, type: "store" },
  { id: "amount", label: "Amount", minWidth: 80 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "created", label: "Created", minWidth: 60, align: "center" },
  { id: "approved", label: "Approved", minWidth: 60, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

const filterStatus = [
  {
    title: "Pending",
    value: "pending",
  },
  {
    title: "Accepted",
    value: "accepted",
  },
];

export default function WithdrawalType(props) {
  console.log(props);
  const classes = useStyles();

  const [openSnackbar] = useSnackbar();
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [withdrawalPaginationData, setWithdrawalPaginationData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "pending",
    driver_id:
      params.get("driver_id") && props.type === "driver"
        ? params.get("driver_id")
        : "",
  });

  async function getWithdrawals() {
    var params = QueryWith(history.location, filters);

    console.log(params);
    if (params.length > 0) {
      history.push({
        pathname: "/withdrawals/" + props.type,
        state: "withdrawal",
        search: params[0],
      });
    }

    ApiService({
      method: "GET",
      route: props.type + "/withdrawal",
      body: filters,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setWithdrawalData(response.data.data.data);
          setWithdrawalPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getWithdrawals();
  }, [filters, history, props.type]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
    //getWithdrawals();
  };

  const approveWithdraw = (c) => {
    ApiService({
      method: "PUT",
      route: props.type + "/withdrawal/accept/" + c.id,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        getWithdrawals();
      }
      openSnackbar(response.data.message);
      return;
      //return response.data ? response.data : [];
    });
  };

  return (
    <div>
      <h1>
        <AccountBalanceWallet /> {props.type} Withdrawals
      </h1>

      <DriverFilter
        value={filters.driver_id}
        style={{
          marginRight: "10px",
        }}
        handleDriverChange={(value) => {
          setFilters({ ...filters, driver_id: value || "", page: 1 });
        }}
      />
      <GSelectbox
        defaultvalue={filters.status}
        showSelectOption={false}
        data={filterStatus}
        id="status_filter"
        option_key="title"
        option_value="value"
        onChange={(newVal) => {
          setFilters({ ...filters, status: newVal, page: 1 });
        }}
        style={{
          border: "1px solid #e2e2e2",
          height: "40px",
          marginRight: "10px",
          minWidth: "150px",
        }}
      />

      {withdrawalPaginationData ? (
        <GPagination
          count={withdrawalPaginationData.total}
          from={withdrawalPaginationData.from || 0}
          to={withdrawalPaginationData.to || 0}
          onChangeNextPage={() =>
            handleChangePage(withdrawalPaginationData.next_page_url)
          }
          onChangePrevPage={() =>
            handleChangePage(withdrawalPaginationData.prev_page_url)
          }
          rowsPerPage={withdrawalPaginationData.per_page}
          prevDisabled={withdrawalPaginationData.prev_page_url ? false : true}
          nextDisabled={withdrawalPaginationData.next_page_url ? false : true}
        ></GPagination>
      ) : (
        ""
      )}

      <Paper className={classes.root}>
        {!withdrawalPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {withdrawalData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type !== props.type)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawalData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/driver/" + c.driver.id + "/orders",
                              state: "driver",
                            }}
                          >
                            {c.driver.title}
                          </Link>
                        </TableRowData>

                        <TableRowData>
                          {settings.currency_symbol}
                          {c.amount}
                        </TableRowData>
                        <TableRowData style={{ textTransform: "uppercase" }}>
                          {c.status}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {filters.status === "pending" ? (
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                approveWithdraw(c);
                              }}
                            >
                              <CheckCircleOutline fontSize="small" />
                            </GButton>
                          ) : (
                            <span>{c.updated}</span>
                          )}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>
    </div>
  );
}
