import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import EventForm from "../forms/eventForm";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";

const columns = [
  { id: "title", label: "Title", minWidth: 200 },
  { id: "date", label: "Date", minWidth: 200, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Events(props) {
  const [eventData, setEventData] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({});
  const [selectedEventId, setSelectedEventId] = useState("");
  const [cevisible, setCevisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEventsList();
  }, []);

  const getEventsList = () => {
    ApiService({ method: "GET", route: "events" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setEventData(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteEvent = () => {
    ApiService({ method: "DELETE", route: "events/" + selectedEventId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getEventsList();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>
        Events
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedEventData({});
            setIsEmpty(true);
            setCevisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>
      <div className="listPaper">
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : eventData.length === 0 ? (
          <GEmpty></GEmpty>
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {eventData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.date}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedEventData(c);
                            setIsEmpty(false);
                            setCevisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedEventId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Event"
          text="Are you sure you want to delete this event?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteEvent();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={cevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCevisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Event Form Modal"
      >
        <EventForm
          cevisible={cevisible}
          setCevisible={setCevisible}
          data={selectedEventData}
          isEmpty={isEmpty}
          onSubmit={getEventsList}
        />
      </Modal>
    </div>
  );
}
