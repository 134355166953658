import React, { useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GTaxiMap from "./orderStopsMap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GView from "../gComponents/GView";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const addressTitle = [
  {
    key: "HOME",
    value: "home",
  },
  {
    key: "OFFICE",
    value: "work",
  },
  {
    key: "OTHER",
    value: "other",
  },
];

export default function CreateAddress(props) {
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.selectedLocation.lat),
          lng: parseFloat(props.selectedLocation.lng),
        }
      : { lat: 30.7046, lng: 76.7179 }
  );
  const initialValues = {
    title: props.selectedLocation.title || "home",
    line1: props.selectedLocation.line1 || "",
    line2: props.selectedLocation.line2 || "",
    rec_name: props.selectedLocation.rec_name || "",
    rec_phone: props.selectedLocation.rec_phone || "",
    lat: props.selectedLocation.lat || "",
    lng: props.selectedLocation.lng || "",
    notes: props.selectedLocation.notes || "",
    city: props.selectedLocation.city || "",
    state: props.selectedLocation.state || "",
    country: props.selectedLocation.country || "",
    zipcode: props.selectedLocation.zipcode || "",
  };

  const onSubmit = (values) => {
    values.title = values.title == "other" ? values.other_title : values.title;
    console.log(values);
    props.onSubmit(values);
    props.setOrderLocVisible(false);
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required("Required"),
    rec_name: Yup.string().required("Required"),
    rec_phone: Yup.string()
      .min("10", "Phone should be atleast 10 didgits")
      .required("Required"),
  });

  if (props.orderLocVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">Confirm Location</div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <br />
              <GView style={{ padding: "0 15px" }}>
                <GTaxiMap
                  center={addressLocation}
                  zoom={16}
                  places={[]}
                  isAutoComplete={true}
                  style={{ height: "300px", position: "relative" }}
                  onChange={(address, loc) => {
                    values.lat = loc.lat();
                    values.lng = loc.lng();
                    values.line1 = address.address;
                    values.city = address.city;
                    values.state = address.state;
                    values.country = address.country;
                    values.zipcode = address.zipcode;
                    setFieldValue("line1", address.address);
                  }}
                />
              </GView>
              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />
              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Save as (Ex. Farmhouse, danny's home)"
                  name="other_title"
                  className="noBorder"
                />
              )}
              <FormikControl
                control="input"
                type="text"
                placeholder="House / Flat / block No. *"
                name="line1"
                className="noBorder"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Landmark / Extra Details"
                name="line2"
                className="noBorder"
              />
              <GText
                g6
                med
                semi
                text="Other Details"
                style={{
                  padding: "15px 15px 10px",
                  textTransform: "uppercase",
                  fontSize: "14px",width:'auto'
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Contact Name*"
                name="rec_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_name", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Contact Phone*"
                name="rec_phone"
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_phone", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                as="textarea"
                placeholder="Address Notes (Optional)"
                name="notes"
              />
              <br />
              <GButton
                variant="condensed"
                children={"Confirm Location"}
                type="submit"
                style={{
                  width: "calc(100% - 30px)",
                  margin: "0 15px 15px",
                  borderRadius: "2px",
                  fontWeight: "550",
                }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
