import React, { useRef, useEffect, useState } from "react";
import { ApiLoginService, ApiService } from "./services";
import { ArrowBackIos } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import appLogo from "./assets/images/app-logo.png";
import GButton from "./gComponents/gButton";
import GText from "./gComponents/gText";

var style = {
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "430px",
  minHeight: "250px",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  margin: "0 auto 0",
  padding: "20px",
  textAlign: "center",
  border: "1px solid #e2e2e2",
  boxSizing: "border-box",
};

var textStyle = {
  background: "#fff",
  border: "1px solid #e2e2e2",
  fontSize: "16px",
  outline: "0",
  color: "#555",
  height: "35px",
  width: "90%",
  padding: "5px 7px 4px",
  margin: "10px",
};

export default function Login() {
  const email = useRef(null);
  const [forgotJson, setForgotJson] = useState({
    email: "",
    otp: "",
    password: "",
  });
  const rconfirmpassword = useRef(null);
  const password = useRef(null);
  const [LoginView, setLoginView] = useState(true);
  const [PassResetView, setPassResetView] = useState(false);
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [PassVerifyView, setPassVerifyView] = useState(false);
  const [openSnackbar] = useSnackbar();

  const handleSignIn = () => {
    let login_data = {
      username: email.current.value,
      password: password.current.value,
    };
    console.log(login_data);
    ApiLoginService("POST", "a/login", login_data)
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          getOauthToken();
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getOauthToken = () => {
    var refresh_token_data = {
      username: email.current.value,
      password: password.current.value,
      grant_type: "password",
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "admins",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        if (!response.status_code) {
          console.log(response);
          localStorage.setItem("access_token", response.access_token);
          localStorage.setItem("refreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("expires_in", now + response.expires_in);
          saveAdminDetails();
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleResetEmail = () => {
    ApiLoginService("POST", "a/forgot-otp", {
      username: forgotJson.email,
    })
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          setIsEmailVerify(true);
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleVerifyOtp = () => {
    console.log(forgotJson);
    ApiLoginService("POST", "a/verify-otp", {
      username: forgotJson.email,
      otp: forgotJson.otp,
    })
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          setPassVerifyView(true);
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangePass = () => {
    if (rconfirmpassword.current) {
      if (forgotJson.password !== rconfirmpassword.current.value) {
        openSnackbar("passowrd does not match");
        return;
      }
    }
    let pData = {
      username: forgotJson.email,
      otp: forgotJson.otp,
      password: forgotJson.password,
    };
    console.log(pData);
    //return;
    ApiLoginService("POST", "a/reset-password", pData)
      .then(function (response) {
        console.log(response);
        if (response.status_code === 1) {
          openSnackbar(response.message);
          setPassVerifyView(false);
          setPassResetView(false);
          setLoginView(true);
        } else {
          openSnackbar(response.message);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveAdminDetails = () => {
    ApiService({ method: "GET", route: "me" }).then(function (response) {
      console.log(response.data);
      if (response.data.status_code === 1) {
        localStorage.setItem("settings", JSON.stringify(response.data.data));
        // console.log(localStorage.getItem("adminData"));
        // saveCountriesList();
        window.location = "/dashboard";
      }
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("expires_in") > 0
    ) {
      window.location = "/dashboard";
    }
  }, []);

  return (
    <div
      style={{
        background: "#f4f5f9",
        height: "100vh",
        padding: "0 10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        src={appLogo}
        alt="logo"
        style={{ width: "250px", display: "block", margin: "20px auto 20px" }}
      />
      <div style={style}>
        {LoginView === true ? (
          <div>
            <GText
              bold
              med
              g1
              text={"Sign in to Admin Panel"}
              style={{ letterSpacing: "0.2px" }}
            />

            <input
              style={textStyle}
              placeholder="you@example.com"
              spellCheck="false"
              ref={email}
            ></input>
            <input
              style={textStyle}
              placeholder="Password"
              type="password"
              spellCheck="false"
              ref={password}
            ></input>

            <GButton
              variant="condensed"
              children="Sign In"
              onClick={handleSignIn}
              style={{
                width: "100%",
                margin: "15px 0",
                height: "45px",
                fontSize: "18px",
              }}
            ></GButton>

            <GButton
              variant="linkable"
              children="Forgot Password?"
              style={{ fontSize: "15px" }}
              onClick={() => {
                setLoginView(false);
                setPassResetView(true);
              }}
            ></GButton>
          </div>
        ) : (
          ""
        )}

        {PassResetView === true ? (
          <div style={{ width: "100%" }}>
            <GButton
              variant="linkable"
              style={{ fontSize: "15px", float: "left" }}
              onClick={() => {
                setLoginView(true);
                setPassResetView(false);
              }}
            >
              <ArrowBackIos
                style={{
                  color: "#000",
                  fontSize: "1.3rem",
                }}
              />
            </GButton>
            <GText
              bold
              g2
              text={"Password Reset"}
              style={{ letterSpacing: "0.2px", margin: "0" }}
            />
            <GText
              light
              text={"To reset your password, enter the email address below"}
              style={{ letterSpacing: "0.2px", margin: "20px 0 15px" }}
            />
            <input
              style={textStyle}
              placeholder="you@example.com"
              spellCheck="false"
              disabled={isEmailVerify === true ? true : false}
              value={forgotJson.email}
              onChange={(e) => setForgotJson({ email: e.target.value })}
              // ref={remail}
            ></input>

            {isEmailVerify === false ? (
              <GButton
                variant="condensed"
                children="Recover Password"
                onClick={handleResetEmail}
                style={{
                  width: "100%",
                  margin: "15px 0",
                  height: "45px",
                  fontSize: "18px",
                }}
              ></GButton>
            ) : (
              <React.Fragment>
                <input
                  style={textStyle}
                  placeholder="*****"
                  spellCheck="false"
                  value={forgotJson.otp}
                  disabled={PassVerifyView === true ? true : false}
                  onChange={(e) =>
                    setForgotJson({ ...forgotJson, otp: e.target.value })
                  }
                ></input>

                {PassVerifyView === true ? (
                  <React.Fragment>
                    <input
                      style={textStyle}
                      placeholder="New password"
                      spellCheck="false"
                      value={forgotJson.password}
                      onChange={(e) =>
                        setForgotJson({
                          ...forgotJson,
                          password: e.target.value,
                        })
                      }
                    ></input>

                    <input
                      style={textStyle}
                      placeholder="Confirm New passwords"
                      spellCheck="false"
                      ref={rconfirmpassword}
                    ></input>

                    <GButton
                      variant="condensed"
                      children="Change Password"
                      onClick={handleChangePass}
                      style={{
                        width: "100%",
                        margin: "15px 0",
                        height: "45px",
                        fontSize: "18px",
                      }}
                    ></GButton>
                  </React.Fragment>
                ) : (
                  <GButton
                    variant="condensed"
                    children="Verify Otp"
                    onClick={handleVerifyOtp}
                    style={{
                      width: "100%",
                      margin: "15px 0",
                      height: "45px",
                      fontSize: "18px",
                    }}
                  ></GButton>
                )}
              </React.Fragment>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
