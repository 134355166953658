import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import CityPopup from "../forms/cityForm";

import GeofencePopup from "../forms/geofenceForm";
import GeofenceEditPopup from "../forms/geofenceEditForm";
import { Add } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GActions from "../gComponents/gActionsStyle";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";

export default function Cities() {
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedCityId, setSelectedCityId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedGeofence, setSelectedGeofence] = useState({});
  const [geofvisible, setGeofvisible] = useState(false);
  const [geofEditvisible, setGeofEditvisible] = useState(false);
  const [cityfvisible, setCityfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  const {
    loading,
    data: citiesData,
    Placeholder,
    refreshData,
    CardShimmer,
  } = useDataFctory("cities", false);

  function deleteCity() {
    var hitUrl = "cities/" + selectedCityId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editCity = (selectedRecord) => {
    setSelectedCity(selectedRecord);
    setIsEmpty(false);
    setCityfvisible(true);
  };
  function deleteGeofence(selectedId) {
    var hitUrl = "geofences/" + selectedId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.status_code === 1) {
          refreshData();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editGeofence = (selectedRecord) => {
    ApiService({ method: "GET", route: "geofences/" + selectedRecord.id })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setSelectedGeofence(response.data.data);
          setIsEmpty(false);
          setGeofEditvisible(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedCity({});
          setIsEmpty(true);
          setCityfvisible(true);
        }}
        style={{
          float: "right",
          marginTop: "5px",
        }}
      />
      <GText g2 bold text={"Cities"} style={{ marginBottom: "15px" }} />
      <div className="listPaper">
        <div
          style={{
            height: "calc(100vh - 270px)",
            overflowY: "auto",
            padding: "5px",
          }}
        >
          {loading === true ? (
            <CardShimmer />
          ) : citiesData.length === 0 ? (
            <Placeholder />
          ) : citiesData.length > 0 ? (
            citiesData.map((c, pi) => {
              return (
                <React.Fragment key={c.id}>
                  <h4 style={{ margin: "10px 0" }}>
                    {c.title} ({c.geofences.length} Geofence)
                    <GActions
                      onEdit={() => editCity(c)}
                      onDelete={() => {
                        setSelectedCityId(c.id);
                        setDialogOpen(true);
                      }}
                      style={{ verticalAlign: "middle" }}
                      menuStyle={{ fontWeight: "400" }}
                    />
                    <GButton
                      variant="linkable"
                      children="Add"
                      onClick={() => {
                        setSelectedGeofence({});
                        setIsEmpty(true);
                        setSelectedCityId(c.id);
                        setGeofvisible(true);
                      }}
                      style={{
                        float: "right",
                      }}
                    >
                      <Add />
                    </GButton>
                  </h4>
                  <hr />
                  {c.geofences.length === 0 ? (
                    <GEmpty></GEmpty>
                  ) : (
                    c.geofences.map((d, ind) => {
                      return (
                        // <div style={{ display: 'inline-block', minWidth: '220px', width: '23%' }}>
                        <GListCard
                          setting="Geofence"
                          title={d.title}
                          listActions={true}
                          id={d.id}
                          key={d.id}
                          onEdit={(g) => {
                            editGeofence(g);
                            setSelectedCityId(c.id);
                          }}
                          onDelete={deleteGeofence}
                          data={d}
                        ></GListCard>
                      );
                    })
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <GEmpty />
          )}
        </div>
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete City"
          text="Are you sure you want to delete this city?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCity();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={cityfvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCityfvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="City Form Modal"
      >
        <CityPopup
          cityfvisible={cityfvisible}
          setCityfvisible={setCityfvisible}
          data={selectedCity}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            refreshData();
          }}
        />
      </Modal>

      <Modal
        isOpen={geofvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setGeofvisible(false)}
        style={{
          content: {
            minWidth: "700px",
            width: "50%",
            maxWidth: "900px",
          },
        }}
        contentLabel="Geofence Create Form Modal"
      >
        <GeofencePopup
          geofvisible={geofvisible}
          setGeofvisible={setGeofvisible}
          data={selectedGeofence}
          selectedCity={selectedCityId}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            refreshData();
          }}
        />
      </Modal>

      <Modal
        isOpen={geofEditvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setGeofEditvisible(false)}
        style={{
          content: {
            minWidth: "700px",
            width: "50%",
            maxWidth: "900px",
          },
        }}
        contentLabel="Geofence Edit Form Modal"
      >
        <GeofenceEditPopup
          geofEditvisible={geofEditvisible}
          setGeofEditvisible={setGeofEditvisible}
          data={selectedGeofence}
          selectedCity={selectedCityId}
          isEmpty={false}
          onSubmit={(response) => {
            openSnackbar(response.message);
            refreshData();
          }}
        />
      </Modal>
    </div>
  );
}
