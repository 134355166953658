import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";

export default function CreateDriver(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const {
    loading,
    data: vehicleTypesData,
    refreshData,
    TableShimmer,
  } = useDataFctory("vehicle_types", false);

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
    commission: props.data ? props.data.commission : 0,
    vehicle_type_id: props.data ? props.data.vehicle_type_id || "" : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "drivers/" + props.data.id : "drivers";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit();
          }
          if (!props.data) {
            if (location.pathname === "/drivers") {
              window.location.reload(false);
            } else {
              window.location = "/drivers";
            }
          }
          props.setCdvisible(false);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string().required("Required"),
    password: !props.data ? Yup.string().required("Required") : "",
    // vehicle_type_id: Yup.string().required("Required"),
  });

  if (props.cdvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Driver
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name*"
                name="first_name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID*"
                name="email"
              />
              <FormikControl
                control="input"
                type="password"
                label={props.data?.id ? "Password" :"Password*"}
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone*"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Service Fee (in %)"
                name="commission"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("commission", event.target.value);
                  }
                }}
              />

              {vehicleTypesData.length >= 0 ? (
                <FormikControl
                  control="select"
                  as="select"
                  label="Vehicle Type"
                  placeholder="Select Vehicle Type"
                  name="vehicle_type_id"
                  options={vehicleTypesData}
                  key_title="title"
                  key_value="id"
                />
              ) : (
                ""
              )}

              <br />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.data ? "Update" : "Create"}
                style={{ width: "calc(100% - 30px", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
