import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/colors.css";
import "./css/forms.css";
import "./css/modal.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    background: {
      default: "#252629",
      paper: "#fff",
    },
    type: "light",
  },
  borders: {
    border: {
      default: "1px solid red",
      paper: "1px soid #e8e8e8",
    },
  },
  palette1: {
    primary: blue,
    background: {
      default: "#252629",
      paper: "#37383D",
    },
    type: "dark",
  },
  typography: {
    body1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
  },
  a: {
    color: {
      default: "#2d4961",
    },
  },
});

// const toastErrorOptions = {
//   style: {
//     backgroundColor: "#a84644",
//   },
// };

function Main() {
  return (
    <React.StrictMode>
      <Router>
        <SnackbarProvider>
          <MuiThemeProvider theme={theme}>
            {/* {"path===" + location} */}
            <App />
          </MuiThemeProvider>
        </SnackbarProvider>
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));

// ReactDOM.render(
//   <React.StrictMode>
//     <Router>
//       <SnackbarProvider>
//         <MuiThemeProvider theme={theme}>
//           {localStorage.getItem("access_token") &&
//           localStorage.getItem("adminData") ? (
//             <div style={mainStyle}>
//               <Sidebar />
//               <div style={contentStyle}>
//                 <Header />
//                 <div
//                   className="mainInnerStyles"
//                   style={{
//                     // padding: "20px",
//                     height: "87vh",
//                     overflow: "overlay",
//                   }}
//                 >
//                   <App />
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <App />
//           )}
//         </MuiThemeProvider>
//       </SnackbarProvider>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
