import React from "react";
import QRCode from 'react-qr-code';
import GButton from "../gComponents/gButton";


export default function GenerateQrCode(props) {
    console.log(props);

    const printDocument = () => {

    var content = document.getElementById("qrDivPrint");
var pri = document.getElementById("ifmcontentstoprint").contentWindow;
pri.document.open();
pri.document.write(content.innerHTML);
pri.document.close();
pri.focus();
pri.print();
      };

      
    if (props.qrVisible === false) {
        return null;
      }
      return (
        <>
           <div className="modal-header">Print QR Code
           
        <GButton variant="linkable" children="Print" onClick={()=>{ printDocument()}} style={{marginLeft:"auto"}}/></div>
      <div className="modal-body">
        
        <div style={{textAlign:'center'}} id="qrDivPrint">
     {props.orderId && <QRCode value={props.orderId.toString()} />}
     </div>
     <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}}></iframe>
      </div></>
      )
}