import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import NotificationSmsForm from "./notificationsSmsForm";
import NotificationEmailForm from "./notificationsEmailForm";
import NotificationPushForm from "./notificationsPushForm";
const Tab = styled.button`
  font-size: 15px;
  padding: 8px 50px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  border-radius: 0px;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #2d4961;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 15px;
`;

const tabTypes = ["SMS", "EMAIL", "PUSH"];
const adminTabTypes = ["EMAIL"];

function NotificationsForm(props) {
  console.log(props);
  const [selectedTab, setSelectedTab] = useState(
    props.userType === "admin" ? adminTabTypes[0] : tabTypes[0]
  );
  const [selectedNofify, setSelectedNotify] = useState(props.data);
  const [notificationVariables, setNotificationVariables] = useState([]);

  const getNotificationVariables = () => {
    ApiService({
      method: "GET",
      route: "notification-variables",
      body: { event_type: props.data.module },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setNotificationVariables(response.data.data);
      }
    });
  };
  useEffect(() => {
    setSelectedNotify(props.data);
    getNotificationVariables();
  }, [props, selectedTab]);

  const handleSubmit = () => {
    props.setNfvisible(false);
    props.onSubmit();
  };
  if (props.nfvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {selectedNofify.title}</div>
      <div className="modal-body">
        <ButtonGroup>
          {props.userType === "admin"
            ? adminTabTypes.map((type) => (
                <Tab
                  key={type}
                  active={selectedTab === type}
                  onClick={() => {
                    setSelectedTab("");
                    setSelectedTab(type);
                  }}
                >
                  {type}
                </Tab>
              ))
            : tabTypes.map((type) => (
                <Tab
                  key={type}
                  active={selectedTab === type}
                  onClick={() => {
                    setSelectedTab("");
                    setSelectedTab(type);
                  }}
                >
                  {type}
                </Tab>
              ))}
        </ButtonGroup>

        {selectedTab === "SMS" ? (
          <NotificationSmsForm
            module={props.data.module}
            eventIdentifier={selectedNofify.identifier}
            userType={props.userType}
            data={selectedNofify.sms}
            onSubmit={handleSubmit}
            variables={notificationVariables}
            notificationType={props.notificationType}
          />
        ) : selectedTab === "EMAIL" ? (
          <NotificationEmailForm
            module={props.data.module}
            eventIdentifier={selectedNofify.identifier}
            userType={props.userType}
            data={selectedNofify.email}
            onSubmit={handleSubmit}
            variables={notificationVariables}
            notificationType={props.notificationType}
          />
        ) : (
          <NotificationPushForm
            module={props.data.module}
            eventIdentifier={selectedNofify.identifier}
            userType={props.userType}
            data={selectedNofify.push}
            onSubmit={handleSubmit}
            variables={notificationVariables}
            notificationType={props.notificationType}
          />
        )}
      </div>
    </>
  );
}
export default NotificationsForm;
