import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GPolygon from "../leaflet/gPolygonEdit";
import useDataFctory from "../useDataFactory";

export default function GeofencePopup(props) {
  const [openSnackbar] = useSnackbar();
  const [selectedPolygons, setSelectedPolygons] = useState(
    props.data.polygon || []
  );
  const { data: citiesData } = useDataFctory("cities", false);

  const initialValues = {
    title: props.data.title || "",
    city_id: props.selectedCity || "",
    type: "polygon",
    polygon: props.data.polygon || [],
  };

  const onSubmit = (values) => {
    console.log(values);
    values.polygon = selectedPolygons;
    console.log(JSON.stringify(values));
    if (values.polygon.length === 0) {
      openSnackbar("Polygon Area is required");
      return;
    }
    // return;

    ApiService({
      method: "PUT",
      route: "geofences/" + props.data.id,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setGeofEditvisible(false);
        props.onSubmit(response.data);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
  });

  if (props.geofvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header" style={{ padding: "20px 25px" }}>
        Edit Geofence
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GButton
                type="submit"
                variant="condensed"
                children="Update"
                style={{
                  position: "absolute",
                  width: "150px",
                  right: "30px",
                  top: "17px",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label="Title"
                name="title"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              {citiesData ? (
                <FormikControl
                  control="select"
                  as="select"
                  label="City"
                  placeholder="Select city"
                  name="city_id"
                  options={citiesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
              ) : (
                ""
              )}

              <div style={{ margin: "15px" }}>
                <label>Area</label>

                <GPolygon
                  polygonData={values.polygon}
                  getGeoJSON={(jsonArray) => {
                    console.log(jsonArray);
                    setFieldValue("polygon", jsonArray);
                    setSelectedPolygons(jsonArray);
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
