import React, { useState, useEffect } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent, GTableContent } from "../gComponents/gContent";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import GSwitch from "../gComponents/gSwitch";
import Orders from "../orders/ordersList";
// import Vehicles from "../components/vehiclesList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../forms/driverForm";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import GMap from "../gComponents/gMaps";
import Modal from "react-modal";

const RoutLinks = [
  {
    title: "Drivers",
    link: "/drivers",
    state: "driver",
  },
];

export default function DriverDetail(props) {
  const [driverId, setDriverId] = useState(props.match.params.driverId);
  // const [driverAssignedVehicle, setDriverAssignedVehicle] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const [typeValue, setTypeValue] = useState("wallet");
  const settings = useSettings(true);

  const faqRoutes = [
    {
      title: "orders",
      link: "/driver/" + driverId + "/orders",
      state: "driver",
    },
    // {
    //   title: "vehicles",
    //   link: "/driver/" + driverId + "/vehicles",
    // },
    {
      title: "wallet",
      link: "/driver/" + driverId + "/wallet",
      state: "driver",
    },
    {
      title: "documents",
      link: "/driver/" + driverId + "/documents",
      state: "driver",
    },
  ];

  const getDriverDetail = () => {
    ApiService({ method: "GET", route: "drivers/" + driverId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDriverData(response.data.data);
          // setDriverAssignedVehicle(response.data.ag_vehicle_info.vehicle_id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.driverId);
    getDriverDetail();
  }, [props, driverId]);

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  function toggleValue(newValue, selectedFieldId) {
    ApiService({
      method: "PUT",
      route: "drivers/" + selectedFieldId,
      body: {
        driver_status: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          driverData.driver_status = newValue;
          setDriverData(driverData);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbImg };
    ApiService({
      method: "PUT",
      route: "drivers/" + driverId,
      body: data,
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setDriverData(response.data.data);
      }
      openSnackbar(response.data.message);
      return;
    });
  }
  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={driverData.title} />
      {driverData ? (
        <GInfo
          id={driverData.id}
          title={driverData.title}
          key={driverData.id}
          className="headerInfo"
          imgType="user"
          photo={driverData?.photo}
          imgEdit={true}
          uploadImage={handleUpdateImage}
          style={{ width: "auto" }}
        />
      ) : (
        ""
      )}
      <GSwitch
        id={driverData.id}
        uId={driverData.id}
        currentValue={driverData.driver_status}
        style={{
          margin: "8px 0 0 25px",
          display: "inline-block",
          verticalAlign: "top",
        }}
        onSwitchChange={toggleValue}
      />

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>
              Contact Details <Edit onClick={() => setCdvisible(true)} />
            </CardHead>
            <GContent title="Email" value={driverData.email} />
            <GContent title="Phone" value={driverData.phone} />
          </div>
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>Other Information</CardHead>
            <GTableContent
              title="Balance"
              value={settings.currency_symbol + "" + driverData.balance}
              align="right"
            />
            <GTableContent
              title="Service Fee"
              value={driverData.commission + "%"}
              align="right"
            />
            <GTableContent
              title="Last location update"
              value={
                driverData.location_updated ? driverData.location_updated : "-"
              }
              align="right"
            />
          </div>

          {driverData.latitude ? (
            <div style={{ margin: "10px" }}>
              {" "}
              <GMap
                centerLocation={{
                  lat: driverData.latitude,
                  lng: driverData.longitude,
                }}
                height="200px"
                type="viewDriverLoc"
                moveMarker={false}
              />{" "}
            </div>
          ) : (
            ""
          )}
        </Card>
        <Modal
          isOpen={cdvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setCdvisible(false)}
          style={{
            content: {
              width: "400px",
            },
          }}
          contentLabel="Driver Form Modal"
        >
          <DriverCreate
            cdvisible={cdvisible}
            setCdvisible={setCdvisible}
            data={driverData}
            onSubmit={getDriverDetail}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "65%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <GTabs routes={faqRoutes} active={typeValue}></GTabs>

        <Switch>
          <Route exact path={"/driver/" + driverId + "/orders"}>
            <Orders
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="agent_id"
            />
          </Route>
          {/* <Route exact path={"/driver/" + driverId + "/vehicles"}>
            <Vehicles
              onChange={handleChange}
              type="driver"
              title="driver_id"
              value={driverId}
              assignedVehicle={driverAssignedVehicle}
              onAssign={handleAssignVehicle}
            />
          </Route> */}
          <Route exact path={"/driver/" + driverId + "/wallet"}>
            <WalletPoints
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="driver_id"
            />
          </Route>
          <Route exact path={"/driver/" + driverId + "/documents"}>
            <Documents
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="driver_id"
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
