import React from "react";
import { MoreVert, Edit, Delete } from "@material-ui/icons";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const ToggleButton = styled.button`
  display: inline-block;
  min-width: auto;
  padding: 5px 6px 1px;
  background: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 50px;
  outline: 0;
  & svg {
    height: 0.8em;
    width: 0.8em;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} style={props.style}>
      <div>
        <ToggleButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVert />
        </ToggleButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: "99", border: "1px solid #f2f2f2" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ padding: "5px 0" }}
                  >
                    {props.onEdit ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onEdit();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        {props.icons != false && <Edit fontSize="small" />} Edit
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {props.onDelete ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onDelete();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        {props.icons != false && <Delete fontSize="small" />}
                        Delete
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    {props.onSelect ? (
                      <MenuItem
                        onClick={(e) => {
                          props.onSelect();
                          handleClose(e);
                        }}
                        style={props.menuStyle}
                      >
                        {props.menuTitle}
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
