import React from "react";
import styled from "styled-components";
import { ImgUrl } from "../services";
import { Avatar } from "@material-ui/core";

import { Link } from "react-router-dom";
const ItemImage = styled.div`
  width: 60px;
  margin-top: 3px;

  &.headerInfo {
    /* width: 70px; */
    .MuiAvatar-root {
      height: 45px;
      width: 45px;
      text-transform: uppercase;
      background: #2d4961;
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }

  &.even {
    .MuiAvatar-root {
      background: #6fa6e6;
      color: #fff;
    }
  }
  &.odd {
    .MuiAvatar-root {
      background: #e688a2;
      color: #fff;
    }
  }
`;

const ItemText = styled.div`
  width: 92%;
  box-sizing: border-box;

  h4 {
    color: #2196f3;
    font-size: 15px;
    margin: 3px 0;
    font-weight: 400;
    text-transform: capitalize;

    & span {
      float: right;
      color: #777;
      font-size: 14px;
      font-weight: 400;
    }
  }
  p {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    margin: 0px;
    line-height: 20px;
    & span {
      float: right;
      color: #a2a2a2;
      font-size: 13.5px;
      font-weight: 400;
    }
  }

  &.headerInfo {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

function GDahboardInfo(props) {
  return (
    <div
      style={{
        display: "inline-flex",
        width: "100%",
        padding: "10px 12px",
        boxSizing: "border-box",
      }}
    >
      <ItemImage className={props.className}>
        <Avatar
          alt={props.title}
          src={ImgUrl(props.imgType) + "/" + props.photo}
        />
      </ItemImage>
      <ItemText className={props.className}>
        <h4>
          <Link to={{ pathname: "/customer/" + props.id + "/orders", state: 'customer' }}>{props.title}</Link>
          <span style={props.rightTopStyle}>{props.rightTopContent}</span>
        </h4>
        <p>
          {props.subtitle}
          <span style={props.rightBottomStyle}>{props.rightBottomContent}</span>
        </p>
        {props.subtitle2 ? <p>{props.subtitle2}</p> : ""}
      </ItemText>
    </div>
  );
}

export default GDahboardInfo;
