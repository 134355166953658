import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import start from "../assets/images/p_marker.png";
import end from "../assets/images/d_marker.png";
import mapStyles from "../jsonData/mapStyles.json";
import GText from "../gComponents/gText";
import CrossIcon from "../assets/images/cross.png";
import { ReactComponent as MarkerIcon } from "../assets/images/map-marker.svg";
import styled from "styled-components";
import GAutoComplete from "../gComponents/gAutoComplete";
import {
  getCity,
  getArea,
  getState,
  getCountry,
  getZipcode,
} from "./geoLocationAdresss";

const InfoWindowStyle = styled.div`
position: absolute;
bottom: 55px;
left: -100px;
width: 220px;
border-radius: 5px;
background-color: #333;
color: #f5f5f5;
box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
padding: 10px;
zIndex: 100;
display: flex;

&::before{
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;bottom: -7px;
  background: #333;left:45%;
  transform: rotate(45deg);
},
`;
// InfoWindow component
const InfoWindow = (props) => {
  const { place, markers } = props;

  return (
    <InfoWindowStyle>
      <GText g6 text={place.line1} style={{ color: "#fff" }} />
      &nbsp;
      <img
        src={CrossIcon}
        style={{
          width: "13px",
          height: "13px",
          cursor: "pointer",
          marginTop: "3px",
        }}
        onClick={() => {
          let selectedIndex = markers.findIndex((m) => m.id == place.id);
          console.log(selectedIndex);
          markers[selectedIndex].show = !markers[selectedIndex].show;
          props.setMarkers([...markers]);
        }}
      />
    </InfoWindowStyle>
  );
};

// Marker component
const Marker = ({ show, place, markers, setMarkers }) => {
  return (
    <div
      style={{
        padding: "5px 5px",
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        transform: "translate(-50%, -90%)",
        position: "relative",
      }}
    >
      {show && (
        <InfoWindow place={place} markers={markers} setMarkers={setMarkers} />
      )}
      <img
        src={place.type == "pickup" ? start : end}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

// Marker component
const LocMarker = ({ locationMarker }) => {
  return (
    <div
      style={{
        padding: "5px 5px",
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        transform: "translate(-50%, -90%)",
        position: "relative",
      }}
    >
      <MarkerIcon style={{ cursor: "pointer" }} />
    </div>
  );
};

export default function GTaxiMap(props) {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapApi, setMapApi] = useState({});
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [center, setCenter] = useState(props.center);
  const [zoom, setZoom] = useState(props.zoom || 10);
  const [markers, setMarkers] = useState([]);
  const [locationMarker, setLocationMarker] = useState(props.center || {});
  const [isDraggable, setIsDraggable] = useState(true);
  useEffect(() => {
    console.log(props);
    props.places.map((m, i) => {
      m.show = false;
      m.id = i;
    });
    setMarkers(props.places || []);
    console.log(mapRef.current);

    if (mapRef.current && locationMarker?.lat && props.isAutoComplete == true) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: parseFloat(locationMarker.lat),
        lng: parseFloat(locationMarker.lng),
      });
      mapRef.current.fitBounds(bounds);
      mapRef.current.setZoom(16);
    }
    if (mapRef.current && props.places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      props.places.map((marker) => {
        bounds.extend({
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        });
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [props]);

  const onChildClickCallback = (key) => {
    console.log(props.isAutoComplete);
    console.log(key);
    if (props.isAutoComplete != true) {
      markers[key].show = !markers[key].show; // eslint-disable-line no-param-reassign
      setMarkers([...markers]);
    }
  };

  function geocodeAddress(latlng) {
    let geocoder = new window.google.maps.Geocoder();
    var latlngNew = {
      lat: latlng.lat,
      lng: latlng.lng,
    };
    console.log(latlngNew);
    geocoder.geocode(
      { location: latlngNew },
      function handleResults(results, status) {
        console.log(results[0]);
        console.log(results[0].address_components);
        var addressArray = results[0].address_components;
        var city = getCity(addressArray);
        var area = getArea(addressArray);
        var state = getState(addressArray);
        var country = getCountry(addressArray);
        var zipcode = getZipcode(addressArray);

        var adreessComponents = {
          address: results[0].formatted_address,
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          country: country ? country : "",
          zipcode: zipcode ? zipcode : "",
        };
        console.log(adreessComponents);
        props.onChange(adreessComponents, results[0].geometry.location);
      }.bind()
    );
  }

  function onCircleInteraction(childKey, childProps, mouse) {
    setIsDraggable(false);
    setLocationMarker({ lat: mouse.lat, lng: mouse.lng });
    console.log("onCircleInteraction called with", childKey, childProps, mouse);
  }
  function onCircleInteraction3(childKey, childProps, mouse) {
    setIsDraggable(true);
    geocodeAddress(mouse);
    console.log("onCircleInteraction called with", childKey, childProps, mouse);
  }

  return (
    <>
      {props.isAutoComplete == true ? (
        <>
          {mapApiLoaded && mapApi ? (
            <GAutoComplete
              map={map}
              mapApi={mapApi}
              onPlacesChanged={(place, latlng, fullAddress) => {
                console.log(place);
                console.log(latlng);
                setLocationMarker({ lat: latlng.lat(), lng: latlng.lng() });
                props.onChange(place, latlng, fullAddress);
              }}
            />
          ) : (
            ""
          )}
          <GoogleMapReact
            draggable={isDraggable}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_KEY,
              libraries: ["places", "geometry"],
            }}
            defaultCenter={props.center}
            defaultZoom={props.zoom}
            options={{ styles: mapStyles }}
            style={Object.assign({}, { height: "300px" }, props.style)}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps, places }) => {
              console.log(maps);
              setMap(map);
              setMapApi(maps);
              setMapApiLoaded(true);
              mapRef.current = map;
            }}
            onChildMouseDown={onCircleInteraction}
            onChildMouseUp={onCircleInteraction3}
            onChildMouseMove={onCircleInteraction}
            onChildClick={() => console.log("child click")}
            onClick={() => console.log("mapClick")}
          >
            <LocMarker lat={locationMarker.lat} lng={locationMarker.lng} />
          </GoogleMapReact>
        </>
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_KEY,
            libraries: ["places", "geometry"],
          }}
          defaultCenter={props.center}
          defaultZoom={props.zoom}
          options={{ styles: mapStyles }}
          style={Object.assign({}, { height: "300px" }, props.style)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChildClick={onChildClickCallback}
        >
          {markers && markers.length > 0
            ? markers.map((marker, i) => {
                return (
                  <Marker
                    key={i}
                    lat={parseFloat(marker.lat)}
                    lng={parseFloat(marker.lng)}
                    show={marker.show}
                    place={marker}
                    markers={markers}
                    setMarkers={setMarkers}
                  />
                );
              })
            : ""}
        </GoogleMapReact>
      )}
    </>
  );
}
