import React, { useState, useEffect } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import useSettings from "../useSettings";

const RuleTypeDiv = styled.div`
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 0.9rem;
    padding: 5px 0;
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.95rem;
    color: #818183;
  }
`;

export const GPricingRuleCard = ({ ruleData }) => {
  const settings = useSettings(false);

  return (
    <RuleTypeDiv>
      {ruleData?.rule_type === "1" ? (
        <div>
          <h5>Fixed Price</h5>
          <p>{ruleData.base_fare} per Delivery</p>
        </div>
      ) : ruleData?.rule_type === "2" ? (
        <div>
          <h5>Distance & Time Based</h5>
          <div>
            <p>BF: {ruleData.base_fare}</p>
            <p>MF: {ruleData.minimum_fare}</p>
            <p>
              <span style={{ textTransform: "uppercase" }}>
                {settings.distance_unit}
              </span>
              :{ruleData.distance_fee}
            </p>
            <p>MIN: {ruleData.duration_fee}</p>
          </div>
        </div>
      ) : (
            <div>
              <h5>Distance Slots Based</h5>
              {ruleData?.options.map((slots, i) => {
                return (
                  <p key={i}>
                    {slots.upto} {settings.distance_unit}: {slots.cost}
                  </p>
                );
              })}
            </div>
          )}
    </RuleTypeDiv>
  );
};

export default GPricingRuleCard;
