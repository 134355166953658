import React, { useState, useEffect } from "react";
import GButton from "./gComponents/gButton";
import Dashboard from "./dashboard/dashboard";
import Orders from "./orders/ordersList";
import OrderCreate from "./orderCreate/index";
import OrderDetail from "./orders/orderDetails";
import OrderInvoice from "./orders/orderInvoice";
import OrderThermalInvoice from "./orders/orderThermalInvoice";
import Customers from "./customers/customersList";
import CustomerDetail from "./customers/customerDetails";
import Drivers from "./drivers/driversList";
import DriverDetail from "./drivers/driverDetails";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import MapView from "./mapView/index";
import Reviews from "./components/reviewsList";
import Coupons from "./components/couponsList";
import BulkNotifications from "./components/bulkPushNotifications";
import Reports from "./reports/reports";
import ReportDetails from "./reports/reportDetails";
import Withdrawals from "./components/withdrawals";
import Transactions from "./components/transactions";
import TransactionsFailed from "./components/transactionsFailed";
import SettingView from "./settings/settings";
import ChatSupport from "./chatSupport/users";
import Login from "./Login";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import Modal from "react-modal";

const mainStyle = {
  display: "inline-flex",
  width: "100%",
};
const contentStyle = {
  // background: '#26252a',
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};
Modal.setAppElement("#root");
export default function App() {
  const [toogleSidebar, setToggleSidebar] = useState(false);
  const location = useLocation();
  let match = useRouteMatch("/order-invoice/:id");
  let match2 = useRouteMatch("/order-thermal-invoice/:id");
  let url = match ? match.url : "";
  let tUrl = match2 ? match2.url : "";

  useEffect(() => {
    const mapUrl = document.getElementById("mapLoad");
    mapUrl.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      process.env.REACT_APP_GOOGLE_KEY +
      "&libraries=geometry,drawing,places";
  }, []);

  const isLoggedIn = () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("expires_in") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  // if (!isLoggedIn()) {
  //   return <Redirect to="/" />;
  // }
  // useEffect(() => {
  //   if (location.pathname == "/map-view") {
  //     setToggleSidebar(true);
  //   }
  // }, [location]);

  return (
    <div>
      {isLoggedIn() &&
      !(url === location.pathname || tUrl === location.pathname) ? (
        <div style={mainStyle}>
          <Sidebar
            style={{
              width: toogleSidebar == true ? "0px" : "18%",
              minWidth: toogleSidebar == true ? "0px" : "165px",
              transition: "width 0.5s ,min-width 0.5s",
            }}
            toggle={toogleSidebar}
          />
          <div
            style={Object.assign({}, contentStyle, {
              width: toogleSidebar == true ? "100%" : "82%",
              transition: "width 0.5s",
            })}
          >
            <Header />
            <div
              className="mainInnerStyles"
              style={{
                // padding: "20px",
                height: "calc(100vh - 54px)",
                width: "100%",
                overflow: "overlay",
                overflow: "auto",
                position: "relative",
              }}
            >
              {location.pathname == "/map-view" && (
                <>
                  {toogleSidebar == true ? (
                    <GButton
                      variant="condensed"
                      style={{
                        position: "absolute",
                        zIndex: "8",
                        top: "0px",
                        padding: "0.2em",
                        left: "0px",
                        background: "#222326",
                        color: "#555",
                        border: "1px solid #101010",
                        borderRadius: "0",
                      }}
                      onClick={() => setToggleSidebar(false)}
                    >
                      <KeyboardArrowRight
                        style={{
                          width: "30px",
                          height: "30px",
                          marginleft: "3px",
                        }}
                      />
                    </GButton>
                  ) : (
                    <GButton
                      variant="condensed"
                      style={{
                        position: "absolute",
                        zIndex: "8",
                        top: "0px",
                        left: "0px",
                        padding: "0.2em",
                        background: "#222326",
                        color: "#777",
                        border: "1px solid #101010",
                        borderRadius: "0",
                      }}
                      onClick={() => setToggleSidebar(true)}
                    >
                      <KeyboardArrowLeft />
                    </GButton>
                  )}
                </>
              )}
              <Switch>
                <Route path="/dashboard" component={Dashboard}></Route>
                <Route path="/orders" component={Orders}></Route>
                <Route path="/orderCreate" component={OrderCreate}></Route>
                <Route path="/order/:orderId" component={OrderDetail}></Route>

                <Route path="/customers" component={Customers}></Route>
                <Route
                  path="/customer/:customerId/:detailType"
                  component={CustomerDetail}
                ></Route>
                <Route
                  path="/driver/:driverId/:detailType"
                  state="driver"
                  component={DriverDetail}
                ></Route>
                <Route
                  path="/drivers"
                  state="driver"
                  component={Drivers}
                ></Route>

                <Route path="/reviews" component={Reviews}></Route>
                <Route path="/map-view" state="map" component={MapView}></Route>

                <Route
                  path="/bulk-notifications"
                  component={BulkNotifications}
                ></Route>
                <Route path="/coupons" component={Coupons}></Route>
                <Route path="/reports" component={Reports}></Route>
                <Route
                  path="/report/details/:identifier"
                  component={ReportDetails}
                ></Route>

                <Route path="/transactions" component={Transactions}></Route>
                <Route
                  path="/failed-payments/:type"
                  component={TransactionsFailed}
                ></Route>
                <Route path="/settings/:settingId" component={SettingView} />
                <Route
                  path="/withdrawals/:withdrawId"
                  component={Withdrawals}
                />
                <Route path="/chat-support" component={ChatSupport} />
                <Route path="/chat-support/:uId" component={ChatSupport} />

                <Redirect to="/dashboard" from="/" />
              </Switch>
            </div>
          </div>
        </div>
      ) : (
        <Switch>
          <Redirect to="/" from="/dashboard" />
          <Route exact path="/" component={Login}></Route>
          <Route
            path="/order-invoice/:orderId"
            component={OrderInvoice}
          ></Route>
          <Route
            path="/order-thermal-invoice/:orderId"
            component={OrderThermalInvoice}
          ></Route>
        </Switch>
      )}
    </div>
  );
}
