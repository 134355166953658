import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { makeStyles, TableContainer, Link } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import { Formik, Form, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import TaxForm from "../components/taxForm";

const useStyles = makeStyles({
  container: {
    padding: "0 18px",
    boxSizing: "border-box",
    maxHeight: "calc(100vh - 250px)",
    overflow: "overlay",
  },
});

export default function TaxSettings(props) {
  const classes = useStyles();
  const [taxData, setTaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    options: [],
  };

  useEffect(() => {
    getTaxList();
  }, []);

  const getTaxList = () => {
    ApiService({ method: "GET", route: "taxes" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          response.data.data.map((c) => {
            c.isDisabled = true;
          });
          setTaxData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setIsBtnDisabled(true);
    ApiService({ method: "POST", route: "taxes", body: values.options[0] })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          response.data.data.isDisabled = true;
          // taxData.push(response.data)
          setTaxData([...taxData, response.data.data]);
          resetForm(initialValues);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    options: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().required("Required"), // these constraints take precedence
          percentage: Yup.string().required("Required"), // these constraints take precedence
        })
      )
      .required("required"),
  });

  return (
    <div className="rightInnerView" style={{ padding: "20px 20px 0" }}>
      <h2>Tax</h2>

      <div className="listPaper">
        {/* {!inputList ? <button onClick={handleAddClick}>Add</button> : ""} */}

        <TableContainer className={classes.container}>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <FieldArray name="options">
                      {(fieldArrayProps) => {
                        console.log(fieldArrayProps);
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { options } = values;
                        return (
                          <React.Fragment>
                            <div>
                              <Link
                                href="#"
                                onClick={(e) => {
                                  push({ title: "", percentage: "" });
                                }}
                                style={{
                                  float: "right",
                                  position: "absolute",
                                  right: "25px",
                                  marginTop: "-70px",
                                  background: "#2d4961",
                                  color: "#fff",
                                  padding: "7px 20px",
                                  textDecoration: "none",
                                }}
                              >
                                Add
                              </Link>
                            </div>

                            {options.map((o, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "inline-flex",
                                    width: "100%",
                                    padding: "0px 0 0 15px",
                                    boxSizing: "border-box",
                                    borderBottom: "1px solid #e2e2e2",
                                  }}
                                  className="arrayFields"
                                >
                                  <div
                                    style={{
                                      padding: "10px 5px",
                                      maxWidth: "250px",
                                      minWidth: "250px",
                                    }}
                                  >
                                    {/* <label>Title</label> */}
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Title"
                                      name={`options[${index}].title`}
                                      divstyle={{ padding: "0" }}
                                      style={{ width: "92%", margin: "5px 0" }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      padding: "10px 5px",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    {/* <label>Percentage</label> */}
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Percentage"
                                      name={`options[${index}].percentage`}
                                      divstyle={{ padding: "0" }}
                                      style={{ width: "92%", margin: "5px 0" }}
                                      maxLength={3}
                                      onChange={(event) => {
                                        const re = /^[0-9.\b]+$/;
                                        if (
                                          event.target.value &&
                                          !re.test(event.target.value)
                                        ) {
                                          return;
                                        } else {
                                          setFieldValue(
                                            `options[${index}].percentage`,
                                            event.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <span
                                    style={{
                                      textAlign: "right",
                                      width: "calc(100% - 420px)",
                                      padding: "13px",
                                      marginTop: "25px",
                                    }}
                                  >
                                    <GButton
                                      type="submit"
                                      variant="condensed"
                                      disabled={isBtnDisabled}
                                      style={{
                                        padding: "10px 25px",
                                        verticalAlign: "bottom",
                                      }}
                                    >
                                      Submit
                                    </GButton>
                                    &nbsp;&nbsp;
                                    <GButton
                                      variant="linkable"
                                      onClick={(e) => {
                                        remove(index);
                                      }}
                                    >
                                      <Close />
                                    </GButton>
                                  </span>
                                </div>
                              );
                            })}
                            {options.length <= 0 && taxData.length <= 0 ? (
                              <GEmpty />
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      }}
                    </FieldArray>
                  </Form>
                )}
              </Formik>

              {taxData.length === 0 ? (
                ""
              ) : (
                <TaxForm
                  data={taxData}
                  onTaxRemove={(id) => {
                    console.log(id);
                    let selectedIndex = taxData.findIndex((p) => p.id == id);
                    console.log(selectedIndex);
                    if (selectedIndex > -1) {
                      taxData.splice(selectedIndex, 1);
                    }
                  }}
                  onTaxUpdate={(data) => {
                    let selectedIndex = taxData.findIndex(
                      (p) => p.id == data.id
                    );
                    if (selectedIndex > -1) {
                      taxData[selectedIndex] = { ...data, isDisabled: true };
                    }
                  }}
                />
              )}
            </>
          )}
        </TableContainer>
      </div>
    </div>
  );
}
