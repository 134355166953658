import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GSelectbox from "../gComponents/gSelectbox";
import { useSnackbar } from "react-simple-snackbar";

const timeslotValues = [
  {
    label: "Today",
    value: "0",
  },
  {
    label: "Tomorrow",
    value: "1",
  },
  {
    label: "+2 days",
    value: "2",
  },
  {
    label: "+3 days",
    value: "3",
  },
  {
    label: "+4 days",
    value: "4",
  },
  {
    label: "+5 days",
    value: "5",
  },
];

export default function TimeslotSettings() {
  const [timeslotSettingsData, setTimeslotSettingsData] = useState({});
  const [timeslotSettingsval, setTimeslotSettingsValue] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [openSnackbar] = useSnackbar();
  const getTimeslotSettings = () => {
    ApiService({
      method: "GET",
      route: "setting/identifier/slot_delivery_day_gap",
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setTimeslotSettingsData(response.data.data);
          setTimeslotSettingsValue(response.data.data.key_value);
          setShowSelect(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTimeslotSettings();
  }, []);

  function getChangedValue(newValue) {
    setTimeslotSettingsValue(newValue);
  }
  const updateTimeslotSettingsVal = () => {
    var hitUrl = "setting/" + timeslotSettingsData.id;

    var switchData = { key_value: timeslotSettingsval };
    ApiService({ method: "PUT", route: hitUrl, body: switchData })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getTimeslotSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Timeslot Settings</h2>
      <div className="listPaper">
        <p>Timeslot Starts from</p>
        <div>
          {showSelect === true ? (
            <GSelectbox
              placeholder="Select value"
              defaultvalue={timeslotSettingsval}
              display="block"
              data={timeslotValues}
              id={timeslotSettingsData.key_title}
              onChange={getChangedValue}
              option_key="label"
              option_value="value"
            />
          ) : (
            ""
          )}
          <br />
          <GButton
            variant="condensed"
            children="Update"
            onClick={updateTimeslotSettingsVal}
          ></GButton>
        </div>
      </div>
    </div>
  );
}
