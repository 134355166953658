import React, {useState, useEffect, useRef} from "react";
import {ApiService} from "../services";
import CustomSeparator from "../gComponents/gBreadCrumbs";
import GInfo from "../gComponents/gInfo";
import {Card, CardHead} from "../styledComponents/cardStyles";
import {GTableContent, GContent} from "../gComponents/gContent";
import GItemTable from "../gComponents/gCartItems";
import {
  AccountBalanceWallet,
  Add,
  Info,
  Send,
  WorkOutlineOutlined,
  PinDropOutlined,
  CheckCircleOutline,
} from "@material-ui/icons";
import {NoteDiv} from "../styledComponents/notesStyles";
import {MessageBox} from "../styledComponents/messageStyles";
import TooltipComponent from "../gComponents/gTooltip";
import OrderTransactionPopup from "../components/OrderTransactionRecieves";
import OrderDeliveryInfo from "../components/orderDeliveryInfo";
import TaskView from "../gComponents/gTaskView";
import TaskStatusTiming from "../gComponents/gTaskStatusView";
import CancelReasonsView from "../components/orderCancelReasons";
import CancelBox from "../gComponents/gCancelBox";
import GAgentView from "../gComponents/gAssignAgentView";
import {useDetectOutsideClick} from "../useDetectOutsideClick";
import GDropdown from "../gComponents/gDropdown";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import {useSnackbar} from "react-simple-snackbar";
import styled from "styled-components";
import OrderTrack from "./googleMap";
import Echo from "laravel-echo";
// import Pusher from "pusher-js";

// import GInput from "../gComponents/gInput";
// import { setNestedObjectValues } from "formik";
const TaskViewDiv = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding: 20px 10px;

  &:last-child {
    border: 0;
  }

  & .taskIcon {
    vertical-align: top;
  }
`;

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderDetail(props) {
  window.Pusher = require("pusher-js");

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "anyKey",
    wsHost: process.env.REACT_APP_WS_HOST,
    wsPort: process.env.REACT_APP_WS_PORT,
    wssPort: process.env.REACT_APP_WS_PORT,
    disableStats: true,
    scheme: process.env.REACT_APP_MODE == "production" ? "https" : "http",
    forceTLS: process.env.REACT_APP_MODE == "production" ? true : false,
  });

  const [openSnackbar] = useSnackbar();
  const [ocrvisible, setOcrvisible] = useState(false);
  const [odInfovisible, setOdInfovisible] = useState(false);
  const [transModalVisible, setTransModalVisible] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  const [orderData, setOrderData] = useState({
    cancelled_log: {},
    assign_log: {},
    order_products: [],
  });
  const [orderStatusList, setOrderStatusList] = useState([]);

  const [orderStatuses, setOrderStatuses] = useState([]);

  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [isNoteBtnDisabled, setIsNoteBtnDisabled] = useState(false);
  const [orderMessages, setOrderMessages] = useState([]);
  const [orderTransaction, setOrderTransactions] = useState([]);
  const [orderFields, setOrderFields] = useState([]);
  const [orderPaySummary, setOrderPaySummary] = useState({
    payment: {},
    data: [],
  });
  const [orderCustomer, setOrderCustomer] = useState({});
  const [orderTask, setOrderTask] = useState([]);
  const [orderAgent, setOrderAgent] = useState({});
  const [selectedTaskInfo, setSelectedTaskInfo] = useState({});
  const settings = useSettings(true);

  const getOrderDetail = () => {
    ApiService({method: "GET", route: "orders/" + orderId})
      .then(response => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderMessages(response.data?.data?.order_messages || []);

          setOrderData(response.data.data);
          setOrderFields(response.data.data.order_fields);
          setOrderTransactions(response.data.data.order_transactions);
          setOrderPaySummary({
            payment: response.data.data.payment_summary,
            data: response.data.data.payment_summary.data,
          });
          setOrderCustomer(response.data.data.customer);
          setOrderTask(response.data.data.tasks || []);
          setOrderAgent(response.data.data.agent || {});
          setOrderStatuses(response.data.options);

          setOrderStatus(response.data.data.status.title);
          setOrderNotes(response.data.data.order_notes);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.Echo.channel("order-updated." + props.match.params.orderId).listen(
      "OrderUpdated",
      function (e) {
        console.log(e);

        console.log(orderStatuses);
        setOrderCustomer(e.order.customer);
        setOrderTask(e.order.tasks || []);
        setOrderAgent(e.order.agent || {});
        setOrderStatus(e.order.status.title);
        return;
        //You can refresh order page here.
      }
    );
    return () => {
      window.Echo.leaveChannel(`order-updated.${props.match.params.orderId}`);
    };
  }, []);

  useEffect(() => {
    console.log(orderMessages);
    console.log("order-message." + props.match.params.orderId);
    window.Echo.channel("order-message." + props.match.params.orderId).listen(
      "OrderMessageSent",
      function (e) {
        console.log(e);
        console.log(orderId);
        // orderMessages.push(e.message);
        // console.log(orderMessages);
        // setOrderMessages([...orderMessages, e.message]);
        // if (orderId) {
        //   getOrderDetail();
        // } else {
        //   setOrderId(e.order_id);
        // }
        if (e?.message?.id) {
          getOrderDetail();
        }
      }
    );
    return () => {
      window.Echo.leaveChannel(`order-message.${props.match.params.orderId}`);
    };
  }, [orderId]);

  useEffect(() => {
    if (orderStatuses && orderStatuses.length > 0 && orderStatus) {
      var keepGoing = true;
      console.log(orderStatuses);
      const listCopy = [...orderStatuses];
      //Logic to update your list here
      console.log(listCopy);

      listCopy.forEach((user, index) => {
        if (keepGoing === true) {
          user["color"] = "colored";
          console.log(user.title + "--" + orderStatus);
          if (user.title === orderStatus) {
            console.log(user.title);
            keepGoing = false;
          }
        } else {
          user["color"] = "";
        }
      });
      setOrderStatusList(listCopy);
    }
  }, [orderStatuses, orderStatus]);

  useEffect(() => {
    console.log(props.match.params.orderId);
    setOrderId(props.match.params.orderId);
  }, [props]);

  useEffect(() => {
    if (orderId) {
      getOrderDetail();
    }
  }, [orderId]);

  function updateField(selectedFieldId, newValue) {
    setIsNoteBtnDisabled(true);
    console.log(newValue);
    console.log(selectedFieldId);
    var post_note = {
      order_id: orderId,
      user_type: "4",
      user_id: "1",
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
          ? messageVal
          : newValue,
    };
    ApiService({
      method: "POST",
      route: "order/" + selectedFieldId,
      body: post_note,
    })
      .then(response => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data.data]);
          }
        }
        setIsNoteBtnDisabled(false);
        openSnackbar(response.data.message);
      })
      .catch(error => {
        console.log(error);
      });
  }

  function updateOrderStatus(status) {
    ApiService({method: "POST", route: "order/" + status + "/" + orderId})
      .then(response => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getOrderDetail();
        }
        openSnackbar(response.data.message);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView" style={{padding: "20px 30px"}}>
      <h1 style={{display: "inline-block"}}>#{orderId} &nbsp;&nbsp;</h1>

      {orderStatus &&
      (orderStatus === "Pending" ||
        orderStatus === "Accepted" ||
        orderStatus === "Started") ? (
        <CancelBox handleClick={() => setOcrvisible(true)} />
      ) : orderStatus === "Cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      {orderStatus && orderStatus === "Pending" ? (
        <GButton
          variant="linkable"
          style={{
            float: "right",
            padding: "10px 20px",
            textDecoration: "underline",
            display: "inline-flex",
            alignItems: "center",
          }}
          onClick={() => updateOrderStatus("accept")}
        >
          <CheckCircleOutline /> &nbsp;Mark as Accepted
        </GButton>
      ) : (
        ""
      )}

      {(orderStatus && orderStatus === "Accepted") ||
      orderStatus === "Started" ? (
        <GButton
          variant="linkable"
          style={{
            float: "right",
            padding: "10px 20px",
            textDecoration: "underline",
            display: "inline-flex",
            alignItems: "center",
          }}
          onClick={() => updateOrderStatus("complete")}
        >
          <CheckCircleOutline /> &nbsp;Mark as Completed
        </GButton>
      ) : (
        ""
      )}

      <CustomSeparator statusList={orderStatusList} class={orderStatus} />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        {orderTask.length > 0 ? (
          <Card>
            <OrderTrack taskData={orderTask} agent={orderAgent} />
          </Card>
        ) : (
          ""
        )}
        <Card>
          <CardHead>Tasks ({orderTask.length})</CardHead>
          {orderTask.map((task, i) => {
            return (
              <TaskViewDiv key={i}>
                {task.type === "pickup" ? (
                  <WorkOutlineOutlined className="taskIcon" />
                ) : (
                  <PinDropOutlined className="taskIcon" />
                )}
                <div
                  style={{
                    width: "calc(100% - 45px)",
                    display: "inline-block",
                    paddingLeft: "15px",
                  }}
                >
                  <CardHead style={{fontSize: "16px", padding: "3px 0 5px"}}>
                    {task.type === "pickup" ? "Pickup" : "Delivery"} Task
                    &nbsp;&nbsp;
                    {/* {task.status === "completed" ? ( */}
                    <span
                      onClick={() => {
                        setSelectedTaskInfo(task);
                        setOdInfovisible(true);
                      }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#2d4961",
                        fontSize: "14px",
                      }}
                    >
                      More Info
                    </span>
                    {/* ) : (
                      ""
                    )} */}
                    <span
                      style={{
                        float: "right",
                        fontSize: "14px",
                        textTransform: "uppercase",
                        padding: "4px 8px 2px",
                        background: "#" + task.status_display.color2,
                        color: "#" + task.status_display.color1,
                      }}
                    >
                      {task.status_display?.title}
                    </span>
                  </CardHead>
                  <TaskStatusTiming
                    data={task}
                    onStatusUpdate={getOrderDetail}
                    orderStatus={orderStatus}
                  />
                  <div
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <TaskView task={task} />
                  </div>
                </div>
                {/* <br /> */}
              </TaskViewDiv>
            );
          })}
        </Card>

        {orderData.order_products.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "inline-block",
              boxSizing: "border-box",
              verticalAlign: "top",
            }}
          >
            <Card>
              <div>
                <CardHead>
                  {orderData.order_products.length === 1 ? "Item" : "Items"} (
                  {orderData.order_products.length})
                </CardHead>
                <div style={{maxHeight: "230px", overflow: "overlay"}}>
                  <GItemTable
                    items={orderData?.order_products}
                    currencySymbol={settings.currency_symbol}
                  />
                </div>
              </div>
            </Card>
          </div>
        )}

        {orderTask?.status ? (
          <div
            style={{
              width: "60%",
              display: "inline-block",
              paddingRight: "15px",
              boxSizing: "border-box",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{padding: "20px 15px 25px"}}>
          <div>
            <CardHead>Customer Details</CardHead>
            <GInfo
              title={orderCustomer.title}
              key={orderCustomer.id}
              subtitle={orderCustomer.phone}
              photo={orderCustomer.thumb_photo}
              imgType="user"
              link={"/customer/" + orderCustomer.id + "/orders"}
            />
          </div>
          <hr style={{margin: "18px 0"}} />
          <div style={{position: "relative"}}>
            <CardHead>Driver Details</CardHead>
            <GAgentView
              orderStatus={orderStatus}
              orderId={orderId}
              taskLog={orderData.assign_log}
              isReAssign={orderStatus === "Pending" ? true : false}
              isAssign={!orderAgent?.id ? false : true}
              driverData={orderAgent}
              onAssignTask={() => getOrderDetail()}
              vehicleTypeId={orderData.vehicle_type_id}
              vehicleTypeTitle={orderData.vehicle_type_title}
            />
          </div>
          <hr style={{margin: "18px 0"}} />

          <div>
            {orderData.pending_payment > 0 && orderStatus !== "Cancelled" ? (
              <GButton
                variant="linkable"
                onClick={() => setTransModalVisible(true)}
                style={{
                  float: "right",
                  textDecoration: "underline",
                  marginTop: "-3px",
                }}
              >
                Recieve Payment
              </GButton>
            ) : (
              ""
            )}
            <CardHead>Payment Details</CardHead>

            {orderTransaction.length > 0 ? (
              orderTransaction.map(s => {
                return (
                  <div key={s.id} style={{borderBottom: "1px solid #e2e2e2"}}>
                    <AccountBalanceWallet
                      style={{
                        float: "right",
                        color: "#777",
                      }}
                    />
                    <p
                      style={{
                        textTransform: "capitalize",
                        margin: "15px 0 10px",
                      }}
                    >
                      {settings.currency_symbol + s.amount}{" "}
                      <span style={{color: "#b2b2b2", fontStyle: "italic"}}>
                        {s.gateway && <>({s.gateway})</>}
                      </span>
                    </p>

                    <span
                      style={{
                        background: "#" + s.background,
                        padding: "2px 5px",
                        fontSize: "14px",
                      }}
                    >
                      {s.status_label}
                    </span>
                    <p
                      style={{
                        color: s.status === "pending" ? "#a2a2a2" : "#333",
                        fontSize: "14px",
                        margin: "12px 0 15px 0",
                      }}
                    >
                      {" "}
                      {s.label}
                    </p>
                  </div>
                );
              })
            ) : (
              <React.Fragment>
                <p
                  style={{
                    margin: "20px 0 5px",
                  }}
                >
                  Pending Amount -{" "}
                  <span
                    style={{
                      textTransform: "capitalize",
                      margin: "5px 0",
                      color: "#777",
                    }}
                  >
                    {settings.currency_symbol + orderData.pending_payment}
                    <span style={{fontStyle: "italic"}}>
                      {" "}
                      ({orderData.gateway})
                    </span>
                  </span>{" "}
                </p>
                <p style={{color: "#a2a2a2", fontSize: "14.5px"}}>
                  {" "}
                  No transaction yet
                </p>
              </React.Fragment>
            )}
          </div>
        </Card>
        <Card>
          <CardHead>Order Fields</CardHead>
          {orderFields && orderFields.length > 0
            ? orderFields.map(p => {
                return (
                  <GContent
                    key={p.id}
                    title={p.title}
                    value={p.value}
                    type="inline"
                  />
                );
              })
            : ""}
          {orderData.vehicle_type_id && (
            <GContent
              key={orderData.vehicle_type_id}
              title={"Vehicle Type"}
              value={orderData.vehicle_type_title || "NA"}
              type="inline"
            />
          )}

          {orderData.distance || orderData.distance >= 0 ? (
            <GContent
              key={orderData.distance}
              title={"Distance"}
              value={orderData.distance + " km"}
              type="inline"
            />
          ) : (
            ""
          )}

          {orderData.duration || orderData.duration >= 0 ? (
            <GContent
              key={orderData.duration}
              title={"Duration"}
              value={orderData.duration + " mins"}
              type="inline"
            />
          ) : (
            ""
          )}
        </Card>
        <Card>
          <CardHead>Payment Summary</CardHead>
          {orderPaySummary.data.map(p => {
            return (
              <GTableContent
                key={p.title}
                title={p.title}
                value={settings.currency_symbol + "" + p.value}
                align="right"
                lAlign="right"
                color="#777"
              />
            );
          })}
          <hr />

          <GTableContent
            title="Total"
            value={
              settings.currency_symbol +
              "" +
              orderPaySummary.payment.total_display
            }
            align="right"
            lAlign="right"
            bold="550"
          />
        </Card>
        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
            marginBottom: "40px",
          }}
        >
          <div style={{padding: "0 15px"}}>
            <CardHead>Notes ({ordernotes.length})</CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px",
              maxHeight: "340px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {ordernotes.map(p => {
              return (
                <NoteDiv key={p.id}>
                  <h5>{p.text}</h5>

                  <p>{p.created}</p>
                </NoteDiv>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={noteVal}
              id="notes"
              placeholder="Add a note.."
              onChange={e => setNoteValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              disabled={isNoteBtnDisabled}
              onClick={() => {
                updateField("notes");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#2d4961",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send style={{fontSize: "1.2rem"}} />
            </GButton>
          </div>
        </Card>
        <Card style={{padding: "15px 0px", position: "relative"}}>
          <div style={{padding: "0 15px"}}>
            <CardHead>
              Messages ({orderMessages.length})
              <TooltipComponent
                title="Messages for Customer & Driver!"
                position="bottom"
                id="order_messages"
              >
                <Info
                  fontSize="small"
                  style={{display: "inline-block", color: "#2d4961"}}
                />
              </TooltipComponent>
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {orderMessages.map(p => {
              return (
                <div
                  key={p.id}
                  style={{textAlign: p.user_type === 4 ? "right" : "left"}}
                >
                  <MessageBox
                    className={p.user_type === 4 ? "send" : "received"}
                  >
                    <h5>{p.text}</h5>

                    <p>
                      {p.user_type === 4 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={messageVal}
              id="messages"
              placeholder="Add a message.."
              onChange={e => setMessageValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              onClick={() => {
                updateField("messages");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#2d4961",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send style={{fontSize: "1.2rem"}} />
            </GButton>
          </div>
        </Card>
      </div>

      {ocrvisible === true ? (
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
        />
      ) : (
        ""
      )}

      {transModalVisible === true ? (
        <OrderTransactionPopup
          transModalVisible={transModalVisible}
          setTransModalVisible={setTransModalVisible}
          linkedId={orderId}
          amount={settings.currency_symbol + orderData.pending_payment}
          pendingAmount={orderData.pending_payment}
          onSubmit={getOrderDetail}
        />
      ) : (
        ""
      )}

      {odInfovisible === true ? (
        <OrderDeliveryInfo
          odInfovisible={odInfovisible}
          setOdInfovisible={setOdInfovisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          notes={selectedTaskInfo?.task_notes}
          images={selectedTaskInfo?.task_images}
          signatures={selectedTaskInfo?.task_signatures}
        />
      ) : (
        ""
      )}
    </div>
  );
}
