import React from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";

export default function CreateCustomer(props) {
  const [openSnackbar] = useSnackbar();
  const location = useLocation();

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
  };

  const onSubmit = (values) => {
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "customers/" + props.data.id : "customers";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit();
          }
          if (!props.data) {
            if (location.pathname === "/customers") {
              window.location.reload(false);
            } else {
              window.location = "/customers";
            }
          }
          props.setCcvisible(false);
        }
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        "Invalid phone number"
      )
      .min(9, "Min length is 10")
      .max(14, "Too Long!")
      .required("Required"),
    password: props.data
      ? ""
      : Yup.string()
          .required("Required")
          .min(6, "Password is too short - should be 6 chars minimum"),
  });

  if (props.ccvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Customer
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="first_name"
                // onChange={(event) => {
                //   const re = /[^A-Za-z]/gi;
                //   if (event.target.value && !re.test(event.target.value)) {
                //     return;
                //   } else {
                //     setFieldValue("first_name", event.target.value);
                //   }
                // }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
              />

              <FormikControl
                control="input"
                type="text"
                label="Password"
                name="password"
              />
              <FormikControl
                control="numberInput"
                type="text"
                label="Phone"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children={props.data ? "Update" : "Create"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
