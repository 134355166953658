import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import GeofencePopup from "../forms/geofenceForm";

export default function Areas() {
  const [geofencesData, setGeofencesData] = useState([]);
  const [selectedGeofence, setSelectedGeofence] = useState({});
  const [geofvisible, setGeofvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();

  const getGeofences = () => {
    var hitUrl = "geofences";

    console.log(hitUrl);

    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setGeofencesData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeofences();
  }, []);

  function deleteGeofence(selectedId) {
    ApiService({ method: "DELETE", route: "geofences/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getGeofences();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editGeofence = (selectedRecord) => {
    setSelectedGeofence(selectedRecord);
    setIsEmpty(false);
    setGeofvisible(true);
  };
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>
        Geofences
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedGeofence({});
            setIsEmpty(true);
            setGeofvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>
      <div className="listPaper">
        {geofencesData.map((d) => {
          return (
            <GListCard
              setting="Geofence"
              title={d.title}
              listActions={true}
              id={d.id}
              key={d.id}
              // onEdit={editGeofence}
              onDelete={deleteGeofence}
              data={d}
            ></GListCard>
          );
        })}

        {geofencesData.length === 0 ? <GEmpty></GEmpty> : ""}
      </div>

      {geofvisible === true ? (
        <GeofencePopup
          geofvisible={geofvisible}
          setGeofvisible={setGeofvisible}
          data={selectedGeofence}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            getGeofences();
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
