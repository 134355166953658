import React, {useState, useEffect} from "react";
import {makeStyles, Paper, TableContainer} from "@material-ui/core";
import {ApiService} from "../services";
import {Assignment, Visibility} from "@material-ui/icons";
import {Link, useHistory} from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import OrderStatuses from "../gComponents/gOrderStatus";
import {DriverFilter} from "../components/driverFilterComponent";
import {CustomerFilter} from "../components/customerFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import GAgentView from "../gComponents/gAssignAgentView";
import {createSocketConnection} from "../useLaravelPusher";
import OrderQrCode from "../components/orderQrCode";
import Modal from "react-modal";

const columns = [
  {id: "id", label: "Id", minWidth: 30, type: "order"},
  {
    id: "customer",
    label: "Customer",
    minWidth: 100,
    type: "customer_id",
    align: "center",
  },
  {
    id: "driver",
    label: "Driver",
    minWidth: 150,
    type: "driver_id",
    align: "center",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    type: "order",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 130,
    type: "order",
    align: "center",
  },
];

const orderSorting = [
  {
    title: "Created At",
    value: "created_at",
  },
  {
    title: "Pickup Time",
    value: "pickup_from",
  },
  {
    title: "Delivery Time",
    value: "delivery_to",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Orders(props) {
  const classes = useStyles();
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [qrVisible, setQrVisible] = useState(false);
  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [orderStatusData, setOrderStatusData] = useState([]);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    order_status: params.get("order_status")
      ? params.get("order_status")
      : "active",
    agent_id: params.get("agent_id") ? params.get("agent_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    delivery_type: params.get("delivery_type")
      ? params.get("delivery_type")
      : "",
    order_type: params.get("order_type") ? params.get("order_type") : "",
    // orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
  });

  const settings = useSettings(true);

  function getOrders() {
    let obj = {};
    if (props.type) {
      props.onChange("orders");
      obj[props.title] = props.value;
      obj["page"] = filters.page;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/orders",
          state: "order",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : filters;

    ApiService({method: "GET", route: "orders", body: query})
      .then(response => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data.data);
          setOrderPaginationData(response.data.data);
          setOrderStatusData(response.data.statuses);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(props);
    getOrders();
  }, [history, filters, props.value]);

  const handleChangePage = pageUrl => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({...filters, page: NewPageNo});
  };
  const handleChangeStatus = oStatus => {
    console.log(oStatus);
    setFilters({...filters, page: "1", order_status: oStatus});
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("admin-updates").listen("OrderPlaced", function (e) {
      console.log(e);

      if (
        filters.page == 1 &&
        (filters.order_status == "active" || filters.order_status == "pending")
      ) {
        getOrders();
      }
    });
    return () => {
      window.Echo.leaveChannel(`admin-updates`);
    };
  }, [orderStatusData]);

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> Orders
          </h1>
          <Link to={{pathname: "/orderCreate", state: "order"}}>
            <GButton
              variant="condensed"
              style={{
                marginTop: "-55px",
                float: "right",
              }}
              children="Add new order"
            />
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={orderStatusData}
            onClickStatus={handleChangeStatus}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div
            style={{
              display: "inline-block",
              width: "calc(100% - 300px)",
            }}
          >
            <DriverFilter
              value={filters.agent_id}
              style={{
                marginBottom: "10px",
                marginRight: "10px",
              }}
              handleDriverChange={value => {
                setFilters({...filters, agent_id: value || "", page: 1});
              }}
            />
            <CustomerFilter
              value={filters.customer_id}
              style={{
                marginRight: "10px",
              }}
              handleCustomerChange={value => {
                setFilters({...filters, customer_id: value || "", page: 1});
              }}
            />

            {/* <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  orderby: newVal,
                  order_status: filters.order_status,
                });
              }}
              style={{
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                setFilters({
                  order: newVal,
                  order_status: filters.order_status,
                });
              }}
            /> */}
          </div>

          {ordersData.length > 0 ? (
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "300px",
              }}
            >
              <GPagination
                style={{display: "inline-block"}}
                count={orderPaginationData.total}
                from={orderPaginationData.from}
                to={orderPaginationData.to}
                onChangeNextPage={() =>
                  handleChangePage(orderPaginationData.next_page_url)
                }
                onChangePrevPage={() =>
                  handleChangePage(orderPaginationData.prev_page_url)
                }
                rowsPerPage={orderPaginationData.per_page}
                prevDisabled={orderPaginationData.prev_page_url ? false : true}
                nextDisabled={orderPaginationData.next_page_url ? false : true}
              ></GPagination>
              {orderPaginationData ? (
                <GPaginationSelect
                  defaultvalue={filters.page}
                  totalPage={orderPaginationData.last_page}
                  onChangePage={NewPageNo =>
                    setFilters({...filters, page: NewPageNo})
                  }
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        ""
      )}

      <Paper
        className={classes.root}
        style={{marginTop: props.type ? "" : "10px"}}
      >
        {ordersData.length !== 0 && props.type ? (
          <GPagination
            count={orderPaginationData.total}
            from={orderPaginationData.from}
            to={orderPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(orderPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(orderPaginationData.prev_page_url)
            }
            rowsPerPage={orderPaginationData.per_page}
            prevDisabled={orderPaginationData.prev_page_url ? false : true}
            nextDisabled={orderPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {!orderPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {ordersData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {columns
                      .filter(column => column.type !== props.title)
                      .map(c => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              maxWidth: c.maxWidth,
                              width: c.width,
                              textAlign: c.align,
                              zIndex: "8",
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                    {!props.title && (
                      <TableHeader style={{textAlign: "center"}}>
                        Actions
                      </TableHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map(s => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          <Link
                            to={{pathname: "/order/" + s.id, state: "order"}}
                          >
                            #{s.id}
                          </Link>
                        </TableRowData>
                        {props.title === "customer_id" ? (
                          ""
                        ) : (
                          <TableRowData style={{textAlign: "center"}}>
                            {s.customer ? (
                              <Link
                                to={{
                                  pathname:
                                    "/customer/" + s.customer.id + "/orders",
                                  state: "customer",
                                }}
                              >
                                {s.customer.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        {props.title === "driver_id" ? (
                          ""
                        ) : (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {s.agent && (
                              <Link
                                to={{
                                  pathname: "/driver/" + s.agent.id + "/orders",
                                  state: "driver",
                                }}
                              >
                                {s.agent.title}
                              </Link>
                            )}
                            {(s.order_status === "pending" ||
                              s.order_status === "rejected" ||
                              s.order_status === "failed") && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <GAgentView
                                  orderStatus={s.status?.title}
                                  orderId={s.id}
                                  taskLog=""
                                  isReAssign={
                                    s.status?.title === "Pending" ? true : false
                                  }
                                  isAssign={!s.agent?.id ? false : true}
                                  driverData={s.agent}
                                  isViewInfo={false}
                                  vehicleTypeId={s?.vehicle_type_id || ""}
                                  vehicleTypeTitle={s.vehicle_type_title || ""}
                                  onAssignTask={() => getOrders()}
                                />
                              </div>
                            )}
                            {s.order_status !== "pending" &&
                            s.order_status !== "rejected" &&
                            s.order_status !== "failed" &&
                            !s.agent
                              ? "-"
                              : ""}
                          </TableRowData>
                        )}
                        <TableRowData style={{textAlign: "center"}}>
                          {settings.currency_symbol}
                          {s.amount_display}
                        </TableRowData>
                        {/* <TableRowData style={{ textAlign: "center" }}>
                              {s.pickup_time ? s.pickup_time : "-"}
                            </TableRowData>
                            <TableRowData style={{ textAlign: "center" }}>
                              {s.delivery_time ? s.delivery_time : "-"}
                            </TableRowData> */}
                        <TableRowData style={{textAlign: "center"}}>
                          <span
                            className="orderStatus"
                            style={{
                              opacity: "1",
                              color: "#" + s.status.color1,
                              background: "#" + s.status.color2,
                            }}
                          >
                            {s.status.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{textAlign: "center"}}>
                          {s.created}
                        </TableRowData>
                        {!props.title && (
                          <TableRowData style={{textAlign: "center"}}>
                            <Visibility
                              title="View QR Code"
                              onClick={() => {
                                setSelectedOrderId(s.id);
                                setQrVisible(true);
                              }}
                              style={{cursor: "pointer"}}
                            />
                          </TableRowData>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      <Modal
        isOpen={qrVisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setQrVisible(false)}
        style={{
          content: {
            width: "400px",
            height: "400px",
          },
        }}
        contentLabel="QR Code Modal"
      >
        <OrderQrCode
          qrVisible={qrVisible}
          setQrVisible={setQrVisible}
          orderId={selectedOrderId}
        />
      </Modal>
    </div>
  );
}
