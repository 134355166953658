import orderIcon from "../assets/images/sidebar/orders.svg";
import customerIcon from "../assets/images/sidebar/customers.svg";
import driverIcon from "../assets/images/sidebar/drivers.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import transactionIcon from "../assets/images/sidebar/transactions.svg";
import reportIcon from "../assets/images/sidebar/reports.svg";
import mapIcon from "../assets/images/sidebar/map.svg";
import supportIcon from "../assets/images/sidebar/chat.svg";

export const menus = [
  {
    icon: orderIcon,
    title: "Orders",
    path: "/orders",
    state: "order",
    isLink: true,
  },
  {
    icon: customerIcon,
    title: "Customers",
    path: "/customers",
    state: "customer",
    isLink: true,
    isAdd: true,
  },
  {
    icon: driverIcon,
    title: "Drivers",
    path: "/drivers",
    state: "driver",
    isLink: true,
    isAdd: true,
    children: [
      {
        icon: "📋",
        title: "Reviews",
        path: "/reviews",
        state: "driver",
        isLink: true,
      },
    ],
  },
  {
    icon: supportIcon,
    title: "Chat Support",
    path: "/chat-support/:uId",
    state: "support",
    isLink: true,
    isAdd: false,
  },
  {
    icon: mapIcon,
    title: "Map View",
    path: "/map-view",
    state: "map",
    isLink: true,
    isAdd: false,
  },
  {
    icon: couponIcon,
    title: "Promotions",
    path: "/coupons",
    state: "coupon",
    isLink: true,
    isAdd: true,
    children: [
      {
        icon: "📋",
        title: "Bulk Notifications",
        path: "/bulk-notifications",
        state: "coupon",
        isLink: true,
      },
    ],
  },
  {
    icon: withdrawalIcon,
    title: "Withdrawals",
    state: "withdrawal",
    isLink: false,
    path: "",
    children: [
      {
        icon: "📋",
        title: "Drivers",
        path: "/withdrawals/driver",
        state: "withdrawal",
        isLink: true,
      },
    ],
  },
  {
    icon: transactionIcon,
    title: "Transactions",
    path: "/transactions",
    state: "transaction",
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Failed Payments",
        path: "/failed-payments/pending",
        state: "transaction",
        isLink: true,
      },
    ],
  },
  {
    icon: reportIcon,
    title: "Reports",
    path: "/reports",
    state: "report",
    isLink: true,
  },
];
