import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";

const columns = [
  { id: "actions", label: "Action", minWidth: 200 },
  { id: "enabled", label: "Enabled", minWidth: 150 },
  { id: "required", label: "Required", minWidth: 60 },
];

export default function TaskActions() {
  const [openSnackbar] = useSnackbar();
  const [taskActionData, setTaskActionData] = useState([]);
  // const [selectedtaskActionData, setSelectedTaskActionData] = useState({});

  const getTaskActions = () => {
    ApiService({ method: "GET", route: "task/actions" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setTaskActionData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTaskActions();
  }, []);

  const toggleValue = (newValue, valueId, keyType, index) => {
    var hitUrl = "task/actions/" + valueId;
    var obj = {};
    obj[keyType] = newValue;
    ApiService({ method: "PUT", route: hitUrl, body: obj })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          taskActionData[index] = response.data.data;
          setTaskActionData(taskActionData);
          // getTaskActions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Task Actions</h2>
      <div className="listPaper">
        <p>Action a driver can in a task</p>
        <hr />

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableHeader
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: column.align,
                    }}
                  >
                    {column.label}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {taskActionData.map((t, ind) => {
                // const [isChecked, setIsChecked] = useState({ t });
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={t.id}>
                    <TableRowData
                      style={{
                        BorderBottom: "1px solid #f4f5f9",
                      }}
                    >
                      {t.title}
                    </TableRowData>
                    <TableRowData>
                      <GSwitch
                        id={"enable" + t.id}
                        uId={t.id}
                        currentValue={t.enable}
                        onSwitchChange={(newValue, valueId, keyType) =>
                          toggleValue(newValue, valueId, keyType, ind)
                        }
                        keyType="enable"
                        //handleToggle={(e) => { switchActionValue(t, 'enable') }}
                      />
                    </TableRowData>
                    <TableRowData>
                      {t.enable === 1 ? (
                        <GSwitch
                          id={"requird" + t.id}
                          uId={t.id}
                          currentValue={t.required}
                          keyType="required"
                          onSwitchChange={(newValue, valueId, keyType) =>
                            toggleValue(newValue, valueId, keyType, ind)
                          }
                          // handleToggle={(e) => {
                          //   switchActionValue(t, "required");
                          // }}
                        />
                      ) : (
                        "-"
                      )}
                    </TableRowData>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
