import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import {Reply, AttachFile} from "@material-ui/icons";
import ChatView from "./chat";
import styled from "styled-components";
import QueryWith from "../Query";
import GTableShimmer from "../gComponents/gTableShimmer";
import {ApiService} from "../services";
import GPagination from "../gComponents/gPagination";
import GButton from "../gComponents/gButton";
import {DriverFilter} from "../components/driverFilterComponent";

const InnerLeft = styled.div`
  box-sizing: border-box;
  width: 25%;
  min-width: 200px;
  display: inline-block;
  background: #fff;
  border-right: 1px solid #e4e6ea;
  border-bottom: 1px solid #e4e6ea;
  padding: 15px 0 10px;

  h4 {
    color: #000;
    margin: 0;
    font-weight: 550;
  }
  ul.orderList {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    height: calc(100vh - 175px);
    overflow: auto;
    overflow: overlay;
    border-top: 1px solid #e4e6ea;
    li {
      padding: 10px 12px;
      border-left: 3px solid transparent;
      border-bottom: 1px solid #e4e6ea;
      // margin: 2px 0;
      a {
        cursor: pointer;
        display: block;
        font-size: 14px;
        color: #333;
      }

      &.active {
        border-left: 3px solid #f7b500;
        background: #f3f5f7;
        a {
          color: #555;
        }
      }
    }
  }
`;

const InnerRight = styled.div`
  box-sizing: border-box;
  width: 75%;
  max-width: calc(100% - 200px);
  display: inline-block;
  vertical-align: top;
  padding: 0 0 10px 0;
  position: relative;
`;

const SupportOrders = () => {
  let {uId} = useParams();
  const [loading, setLoading] = useState(true);
  const [userChatsPaginationData, setUserChatsPaginationData] = useState("");
  const [userChatsData, setUserChatsData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(uId || "");
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
  });

  useEffect(() => {
    window.Echo.channel("admin-support-updates").listen(
      "SupportMessageSent",
      function (e) {
        setUserChatsData(
          userChatsData.map(m =>
            m.user_id == e.message.user_id ? e.message : m
          )
        );
      }
    );
    return () => {
      window.Echo.leaveChannel("admin-support-updates");
    };
  }, [userChatsData]);

  useEffect(() => {
    getOrders();
  }, [filters]);

  function getOrders() {
    var params = QueryWith(history.location, filters);

    ApiService({method: "GET", route: "support/message-list", body: filters})
      .then(response => {
        console.log(response);
        if (response.data.status_code === 1) {
          setUserChatsData(response.data.data?.data || []);
          setUserChatsPaginationData(response.data?.data);
          if (response.data.data?.data.length > 0) {
            setSelectedUserId(response.data.data?.data[0].user_id || "");
            setSelectedUserInfo(response.data.data?.data[0] || {});
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }
  const handleChangePage = pageUrl => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({...filters, page: NewPageNo});
  };

  useEffect(() => {
    console.log(userChatsData);
    console.log(selectedUserId);
    if (userChatsData && userChatsData.length > 1 && !uId) {
      history.push({
        pathname: `${"/chat-support/" + selectedUserId}`,
        state: "support",
      });
    }
  }, [selectedUserId]);

  return (
    <div
      style={{
        display: "flex",
        // height: "calc(100vh - 55px)",
        height: "100%",
        overflow: "hidden",
        background: "#f5f5f5",
      }}
    >
      <InnerLeft>
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px 17px 2px",
            fontSize: "18px",
            fontWeight: 650,
          }}
        >
          Chats&nbsp;
          {userChatsData.length > 0 ? <>({userChatsData.length})</> : ""}
          {userChatsPaginationData.last_page > 1 && (
            <GPagination
              style={{display: "inline-block", marginLeft: "auto"}}
              ulstyle={{paddingLeft: "10px", margin: "0"}}
              count={userChatsPaginationData.total}
              from={userChatsPaginationData.from || 0}
              to={userChatsPaginationData.to || 0}
              onChangeNextPage={() =>
                handleChangePage(userChatsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(userChatsPaginationData.prev_page_url)
              }
              rowsPerPage={userChatsPaginationData.per_page}
              prevDisabled={
                userChatsPaginationData.prev_page_url ? false : true
              }
              nextDisabled={
                userChatsPaginationData.next_page_url ? false : true
              }
            ></GPagination>
          )}
        </h4>
        <DriverFilter
          value={""}
          placeholder={"Start New Chat"}
          style={{
            margin: "10px 17px 8px",
            width: "calc(100% - 34px)",
          }}
          hideSelectedVal={true}
          handleDriverChange={(id, value) => {
            setSelectedUserId(id || "");
            setSelectedUserInfo(id ? value : {});
          }}
        />
        {loading ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <ul className="orderList">
            {userChatsData.map((d, i) => {
              return (
                <li
                  className={selectedUserId == d.user_id ? "active" : ""}
                  key={i}
                >
                  <a
                    onClick={() => {
                      setSelectedUserId(d.user_id);
                      setSelectedUserInfo(d);
                    }}
                  >
                    <div style={{display: "flex"}}>
                      <h4
                        style={{
                          fontSize: "17px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontWeight: 550,
                          flex: 1,
                        }}
                      >
                        {d.user_title}{" "}
                      </h4>
                      <p
                        style={{
                          color: "#CECFD1",
                          fontSize: "12px",
                          margin: "2px 5px 2px auto",
                          fontWeight: 550,
                        }}
                      >
                        {d.created}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0",
                      }}
                    >
                      {d.admin_id && (
                        <Reply
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            fill: "#CCCCCE",
                          }}
                        />
                      )}
                      <p
                        style={{
                          color: "#C9C9C9",
                          fontSize: "14px",
                          margin: "2px 0",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          letterSpacing: "0.2px",
                        }}
                      >
                        {d.message_type == "image" ? (
                          <div style={{display: "flex", alignItems: "center"}}>
                            <AttachFile
                              style={{
                                fontSize: "1rem",
                                fill: "#CCCCCE",
                                transform: "rotate(45deg)",
                                marginRight: "2px",
                              }}
                            />
                            Photo
                          </div>
                        ) : (
                          d.message
                        )}
                      </p>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </InnerLeft>
      <InnerRight>
        <ChatView userId={selectedUserId} userInfo={selectedUserInfo} />
      </InnerRight>
    </div>
  );
};

export default SupportOrders;
