import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import GSwitch from "../gComponents/gSwitch";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEditable from "../gComponents/gEditable";
import useSettings from "../useSettings";

export default function TaskAllocation() {
  const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSettings();
  const [autoAssignSetting, setAutoAssignSetting] = useState({
    delivery_autoassign: {},
  });
  const [taskAllocationData, setTaskAllocationData] = useState({
    retries: {},
    task_available_time: {},
    allocation_radius: {},
    stay: {},
  });

  const fieldStyle = {
    display: "inline-block",
    minWidth: "200px",
    padding: "10px 15px 10px 0",
  };

  const getTaskAllocations = () => {
    ApiService({ method: "GET", route: "setting/task_allocation" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setTaskAllocationData(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTaskAllocations();
    getAutoAssignSetting();
  }, []);

  const getAutoAssignSetting = () => {
    ApiService({ method: "GET", route: "setting/delivery" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setAutoAssignSetting(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function toggleValue(newValue) {
    console.log(newValue);
    updateField(newValue, taskAllocationData.stay.id, "stay");
    //setTaskAllocationData({ stay: newValue });
  }

  function updateField(newValue, selectedFieldId, stitle, type) {
    console.log(newValue);
    console.log(selectedFieldId);
    //return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService({ method: "PUT", route: hitUrl, body: send_data })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          if (type && type === "delivery") {
            autoAssignSetting[stitle].key_value = newValue;
            setAutoAssignSetting(autoAssignSetting);
          } else {
            taskAllocationData[stitle].key_value = newValue;
            setTaskAllocationData(taskAllocationData);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Task Allocation</h2>
      <div className="listPaper">
        <p>
          Set the parameters below to change the available task time and the
          retries count for the Drivers{" "}
        </p>

        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <React.Fragment>
            <div style={fieldStyle}>
              <GEditable
                key={taskAllocationData.retries.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.retries.key_value}
                placeholder="Number of Retries"
                id={taskAllocationData.retries.id}
                onSubmit={(val, id) => updateField(val, id, "retries")}
                style={{ margin: "10px 0 20px" }}
              />

              <GEditable
                key={taskAllocationData.task_available_time.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.task_available_time.key_value}
                placeholder="Task available Time (in sec)"
                id={taskAllocationData.task_available_time.id}
                onSubmit={(val, id) =>
                  updateField(val, id, "task_available_time")
                }
                style={{ margin: "10px 0 20px" }}
              />

              <GEditable
                key={taskAllocationData.allocation_radius.id}
                type="text"
                title={true}
                editable={false}
                defaultValue={taskAllocationData.allocation_radius.key_value}
                placeholder={
                  "Radius of Allocation (in " + settings.distance_unit + ")"
                }
                id={taskAllocationData.allocation_radius.id}
                onSubmit={(val, id) =>
                  updateField(val, id, "allocation_radius")
                }
                style={{ margin: "10px 0 20px" }}
              />
            </div>

            <hr />
            <p>
              Keep Task with the last available Driver if Drivers limit is
              reached.
            </p>
            <GSwitch
              id={taskAllocationData.stay.id}
              uId={taskAllocationData.stay.id}
              currentValue={taskAllocationData.stay.key_value}
              onSwitchChange={toggleValue}
            />

            <br />
            {autoAssignSetting.delivery_autoassign?.id && (
              <>
                <p>{autoAssignSetting.delivery_autoassign.key_display_title}</p>
                <GSwitch
                  id={autoAssignSetting.delivery_autoassign.id}
                  uId={autoAssignSetting.delivery_autoassign.id}
                  currentValue={autoAssignSetting.delivery_autoassign.key_value}
                  onSwitchChange={(newValue) =>
                    updateField(
                      newValue,
                      autoAssignSetting.delivery_autoassign.id,
                      "delivery_autoassign",
                      "delivery"
                    )
                  }
                />
              </>
            )}
          </React.Fragment>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
