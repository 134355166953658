import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function GdatePicker(props) {
  const { label, name, id, value, format,minDate,minTime, maxTime, ...rest } = props;

  return (
    <React.Fragment>
      {label ? <label htmlFor={name}>{label}</label> : ""}
      {/* <texarea name={name} {...rest}>
        {value} */}
      <DatePicker
        id={id}
        selected={value}
        dateFormat={format}
        onChange={(date) => props.onChange(date)}
        minDate={minDate || null}
        minTime={minTime || null}
        maxTime={maxTime || null}
        showTimeSelect={props.showTimeSelect || false}
      />
      {/* </texarea> */}
    </React.Fragment>
  );
}

export default GdatePicker;
