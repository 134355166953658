import React, { useState, useEffect } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";
import { matchPath } from "react-router-dom";

import useSettings from "../useSettings";

function OrderInvoice(props) {
  const setting = useSettings();
  const [orderId, setOrderId] = useState(props.match.params.orderId);
  const [invoiceData, setInvoiceData] = useState({});
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });
  const match = matchPath(props.match.params.orderId, {
    path: "/order-invoice/:id",
    exact: true,
    strict: false,
  });

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.orderId + "==" + orderId);
    setOrderId(props.match.params.orderId);
    const getOrderDetail = () => {
      var hitUrl = "orders/" + orderId;

      ApiService({ method: "GET", route: hitUrl })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
            getInvoiceSettings();
            console.log(invoiceData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getOrderDetail();
  }, []);

  return (
    <div className="invoiceDiv">
      <div
        className="flex"
        style={{
          width: "40%",
        }}
      >
        <img
          src={ImgUrl("logo") + "/" + linksData.invoice_logo.key_value}
          alt="logo"
          onError={addDefaultLogo}
        />

        <label>{invoiceData.store ? invoiceData.store.title : ""}</label>
        <p>{invoiceData.store ? invoiceData.store.address : ""}</p>
      </div>
      <div
        className="flex"
        style={{
          width: "60%",
        }}
      >
        <div>
          <p>{linksData.invoice_header_text.key_value}</p>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Order Date</label>
            <p>{invoiceData.store ? invoiceData.created : ""}</p>

            <br />

            <label>Invoice Number</label>
            <p>{"#" + orderId}</p>
          </div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Invoice</label>
            <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
            <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
            <p>
              {invoiceData.delivery
                ? invoiceData.delivery.delivery_address
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th style={{ textAlign: "right" }}>Total Price</th>
            </tr>
          </thead>
          {invoiceData.order_products ? (
            <tbody>
              {invoiceData.order_products.map((item, ind) => {
                return (
                  <tr>
                    <td>
                      {item.title}
                      <br />
                      {item.variants.map((v, $index) => {
                        return (
                          <small
                            key={v.id}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              display: "inline-block",
                              paddingRight: "5px",
                              lineHeight: "20px",
                            }}
                          >
                            <span style={{ color: "#555" }}>{v.value}</span>
                            {item.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </small>
                        );
                      })}
                    </td>
                    <td>{item.quantity}</td>
                    <td>
                      {item.price}/{item.unit}
                    </td>
                    <td>{item.quantity * item.price}</td>
                  </tr>
                );
              })}

              <tr className="nopadding noheight payment">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {invoiceData.payment_summary.data.map((payment, ind) => {
                return (
                  <tr className={payment.title + " payment"}>
                    <td></td>
                    <td></td>
                    <td>{payment.title}</td>
                    <td>{payment.value}</td>
                  </tr>
                );
              })}

              <tr className="payment">
                <td></td>
                <td></td>
                <td>Total ({invoiceData.payment_summary.gateway})</td>
                <td>{invoiceData.payment_summary.total}</td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <label>
        Thank you for using the services of {setting?.project_name}.
      </label>
      <p>Powered by JHL</p>
      <br />
      <p>{linksData.invoice_footer_text.key_value}</p>
    </div>
  );
}

export default OrderInvoice;
