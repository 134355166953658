import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { makeStyles, TableContainer, Link } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";

const columns = [
  { id: "title", label: " Time (in mins)", minWidth: 140 },
  { id: "valueType", label: "Value Type", minWidth: 180, align: "center" },
  { id: "value", label: "Value", minWidth: 140, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function ExpressCost(props) {
  const classes = useStyles();
  const [expressCostData, setExpressCostData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedExpressCostId, setSelectedExpressCostId] = useState("");

  useEffect(() => {
    getEventsList();
  }, []);

  const getEventsList = () => {
    ApiService({ method: "GET", route: "express-cost" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setExpressCostData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteExpCost = () => {
    var hitUrl = "event/" + selectedExpressCostId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getEventsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Express Cost</h2>

      <div className={classes.root}>
        {!expressCostData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {expressCostData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.date}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Link href="#">
                          <Edit fontSize="small" />
                        </Link>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedExpressCostId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {expressCostData.length === 0 ? <GEmpty></GEmpty> : ""}
          </TableContainer>
        )}

        {dialogOpen === true ? (
          <GConfirmDialog
            open={dialogOpen}
            title="Delete Express Cost"
            text="Are you sure you want to delete this express cost?"
            handleClose={() => setDialogOpen(false)}
            onConfirmClose={() => {
              setDialogOpen(false);
              deleteExpCost();
            }}
          ></GConfirmDialog>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
