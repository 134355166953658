import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";

export default function DriverAppSettings() {
  const [taskSettingsData, setTaskSettingsData] = useState([]);
  // const [selectedtaskActionData, setSelectedTaskActionData] = useState({});

  const getOrderSettings = () => {
    ApiService({ method: "GET", route: "setting/task" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTaskSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderSettings();
  }, []);

  function toggleValue(newValue, valueId) {
    console.log(newValue + "--" + valueId);
    var hitUrl = "setting/" + valueId;
    var send_data = {
      key_value: newValue,
    };
    console.log(hitUrl);
    console.log(send_data);
    //return;
    ApiService({ method: "PUT", route: hitUrl, body: send_data })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getOrderSettings();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>Driver App</h2>
      <div className="listPaper">
        <Table>
          <TableBody>
            {taskSettingsData
              .filter(
                (setting) => setting.key_title === "arrived_stage_enabled"
              )
              .map((t) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={t.id}>
                    <TableRowData style={{ width: "40%" }}>
                      {t.key_display_title}
                    </TableRowData>
                    <TableRowData>
                      <GSwitch
                        id={t.id}
                        uId={t.id}
                        currentValue={t.key_value}
                        onSwitchChange={toggleValue}
                        // handleToggle={(e) => {
                        //   switchActionValue(t);
                        // }}
                      />
                    </TableRowData>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
