import React, {useEffect, useState} from "react";
import {ApiService} from "../services";
import RightListView from "./listview";
import MapLoader from "./map";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import {KeyboardArrowRight, KeyboardArrowLeft} from "@material-ui/icons";
import {GHorCardShimmer, GMapShimmer} from "../gComponents/gHorCardShimmer";
import {DriverFilter} from "../components/driverFilterComponent";
import {createSocketConnection} from "../useLaravelPusher";
import useSettings from "../useSettings";
import {GMultiSelectbox} from "../gComponents/gMultiSelectbox";

const Div = styled.div`
  width: 100%; 
  transition: width 0.5s;position:relative;

  &.toggle {
    width: calc(100% - 420px); 
`;

const driverTypes = [
  {
    type: 1,
    title: "In transit",
    color: "#43829B",
  },
  {
    type: 2,
    title: "Idle",
    color: "#76A543",
  },
  {
    type: 3,
    title: "Offline",
    color: "#9D9E9D",
  },
];

const loadGoogleMapScript = callback => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

export default function DriverMapView() {
  // const MapLoader = withScriptjs(Map);

  const settings = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [driversData, setDriversData] = useState([]);
  const [listView, setListview] = useState(false);
  const [locationsData, setlocationsData] = useState({
    drivers: [],
  });
  const [updatedDriver, setUpdatedDriver] = useState({});
  const [isLocateDriver, setIsLocateDriver] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [routeJson, setRouteJson] = useState({});
  const [loadMap, setLoadMap] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [filters, setFilters] = useState({
    driver_types: [
      {
        type: 1,
        title: "In transit",
        color: "#43829B",
      },
    ],
  });

  const getMapLists = () => {
    ApiService({method: "GET", route: "drivers?per_page=150"})
      .then(response => {
        console.log(response);

        response.data.data.data.map(d => {
          if (d.driver_status == 0) {
            d.type = 3;
          }
          if (d.driver_status == 1) {
            if (d.active_task_count > 0) {
              d.type = 1;
            } else {
              d.type = 2;
            }
          }
        });

        setDriversData(response.data.data.data || []);
        setIsLoading(false);
        setIsMapLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("agent-locations").listen(
      "LocationUpdated",
      function (e) {
        console.log(e);
        setUpdatedDriver({
          id: e.driver_id,
          latitude: e.location.latitude,
          longitude: e.location.longitude,
          location_updated: "just now",
        });

        //You can refresh order page here.
      }
    );
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
    getMapLists();
    return () => {
      window.Echo.leaveChannel(`agent-locations`);
    };
  }, []);

  useEffect(() => {
    console.log(filters);
    let filteredArray = driversData.filter(o1 =>
      filters.driver_types.some(o2 => o1.type === o2.type)
    );
    console.log(filteredArray);
    locationsData.drivers = filteredArray.filter(d => d.latitude);
    setlocationsData({...locationsData});
  }, [filters]);

  useEffect(() => {
    if (isMapLoading == false) {
      setSelectedRoute([]);
      let filteredArray = driversData.filter(o1 =>
        filters.driver_types.some(o2 => o1.type === o2.type)
      );

      console.log(filteredArray);
      console.log("ccc");

      setlocationsData({
        drivers:
          filters.driver_types.length == 0
            ? []
            : filteredArray.length == 0
            ? driversData.filter(d => d.latitude)
            : filteredArray.filter(d => d.latitude),
      });
    }
  }, [driversData, isMapLoading]);

  return (
    <>
      <div style={{background: "#151515", display: "flex"}}>
        <Div
          className={listView == true ? "toggle" : ""}
          style={{
            // width: isTaskView == true ? "calc(100% - 300px)" : "100%",
            display: "inline-block",
          }}
        >
          {isLoading === true ? (
            <div style={{height: "calc(100vh - 120px)", overflow: "hidden"}}>
              <GMapShimmer theme="dark"></GMapShimmer>
              <GMapShimmer theme="dark"></GMapShimmer>
            </div>
          ) : (
            <>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <MapLoader
                  markers={selectedRoute || []}
                  locationsData={locationsData}
                  driversLocations={driversData.filter(d => d.latitude) || []}
                  updatedDriver={updatedDriver}
                  chooseDriver={selectedDriver}
                  isLocateDriver={isLocateDriver}
                  setIsLocateDriver={setIsLocateDriver}
                  routeJsonData={routeJson}
                />
              )}

              <GButton
                variant="condensed"
                onClick={() => {
                  setListview(!listView);
                }}
                style={{
                  position: "absolute",
                  zIndex: "9",
                  top: "0px",
                  right: "0",
                  border: "1px solid #101010",
                  borderRight: "0",
                  borderRadius: "0",
                  padding: "10px 14px",
                  background: "#222326",
                  color: "#777",
                  padding: "0.2em",
                }}
              >
                {listView == true ? (
                  <KeyboardArrowRight
                    style={{
                      width: "30px",
                      height: "30px",
                      marginLeft: "3px",
                    }}
                  />
                ) : (
                  <KeyboardArrowLeft
                    style={{
                      width: "30px",
                      height: "30px",
                      marginLeft: "3px",
                    }}
                  />
                )}
              </GButton>
            </>
          )}

          <div
            style={{
              background: "#151515",
              display: "flex",
              alignItems: "center",
              padding: "6px 20px",
              height: "49px",
              borderTop: "1px solid #101010",
            }}
          >
            <GMultiSelectbox
              data={driverTypes}
              title={"Drivers"}
              countData={driversData.filter(d => d.latitude)}
              values={filters.driver_types}
              handleChange={v => {
                console.log(v);
                setFilters({driver_types: v});
                setSelectedDriver({});
                setIsLocateDriver(false);
              }}
            />
          </div>
        </Div>

        {listView == true ? (
          <RightListView
            setListview={setListview}
            listView={listView}
            settings={settings}
            filters={filters}
            updatedDriver={updatedDriver}
            driversData={driversData}
            onViewRoute={(task, taskJson) => {
              console.log(task);
              // if (task.length <= 0) {
              //   return;
              // }
              //return;
              // const route = [
              //   {
              //     latitude: task?.pickup_lat,
              //     longitude: task?.pickup_lng,
              //     formatted: task?.pickup_address,
              //     orderId: task?.order_id,
              //   },
              //   {
              //     latitude: task?.delivery_lat,
              //     longitude: task?.delivery_lng,
              //     formatted: task?.delivery_address,
              //     orderId: task?.order_id,
              //   },
              // ];
              // console.log(route);
              setSelectedRoute(task?.tasks || []);
              setRouteJson(taskJson || {});
              setSelectedDriver({});
              setIsLocateDriver(false);
            }}
            onLocateDriver={d => {
              console.log(d);
              setSelectedDriver(d);
              setIsLocateDriver(true);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
