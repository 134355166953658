import React, { useState, useEffect } from "react";
import { ImgUrl, ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { useSnackbar } from "react-simple-snackbar";

export default function InvoiceSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [appData, setAppData] = useState({
    i_c_url: {},
    a_c_url: {},
  });

  useEffect(() => {
    getSocialSettings();
  }, []);

  const getSocialSettings = () => {
    var hitUrl = "setting/app";

    console.log(hitUrl);

    ApiService({ method: "GET", route: "setting/app" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setAppData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService({ method: "PUT", route: hitUrl, body: send_data })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          //getSocialSettings();
          appData[field] = response.data.data;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>App Settings</h2>

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "60%",
            minWidth: "540px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={appData.i_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.i_c_url.key_value}
            className={props.display}
            placeholder={appData.i_c_url.key_display_title}
            id={appData.i_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "i_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={true}
          />

          <GEditable
            key={appData.a_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.a_c_url.key_value}
            className={props.display}
            placeholder={appData.a_c_url.key_display_title}
            id={appData.a_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "a_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={true}
          />
        </div>
      </div>
    </div>
  );
}
