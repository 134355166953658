import React, { useEffect } from "react";
import { ApiService } from "./services";
import useLocalStorage from "./useAsyncStorage";

const useSettings = (refresh = false) => {
  const [settings, setSettings] = useLocalStorage("settings", data);

  const getSettings = () => {
    ApiService({ method: "GET", route: "me" }).then((response) => {
      if (response.data?.data) {
        console.log(response.data);
        // global.settings = response.data.data;
        setSettings(response.data.data);
      }
    });
  };
  useEffect(() => {
    // console.log(refresh);
    if (refresh === true) {
      if (
        localStorage.getItem("access_token") &&
        localStorage.getItem("expires_in") > 0
      ) {
        getSettings();
      }
    }
  }, []);
  return settings;
};

export default useSettings;

const data = {
  user: {
    wallet: 0,
  },
};
