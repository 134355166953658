import React, { useState, useEffect, useContext } from "react";
import GView from "../gComponents/GView";
import GText from "../gComponents/gText";
import VehicleSection from "./orderVehicles";
import OrderStops from "./orderStops";
import GTaxiMap from "./orderStopsMap";
import FormSection from "./orderForm";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import CheckoutForm from "./orderCheckout";
import Modal from "react-modal";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../gComponents/gShimmer";
import { CustomerFilter } from "../components/customerFilterComponent";
import GEmpty from "../gComponents/gEmpty";
import GdatePicker from "../gComponents/gDatePicker";
import moment from "moment";
import styled from "styled-components";
import GInput from "../gComponents/gOrderFormInput";

const DateTimePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__tab-loop .react-datepicker-popper {
    left: -15px !important;
  }
  .react-datepicker__input-container {
    input {
      outline: none;
      border: none;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 12px;
      border: 1px solid #e2e2e2;
    }
  }
`;

const OrderCreate = () => {
  const [currentHour, setCurrentHours] = useState(new Date().getHours());
  const [currentMins, setCurrentMins] = useState(new Date().getMinutes());
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const { addresses, fields, vehicle_types } = formData;
  const [openSnackbar] = useSnackbar();
  const settings = useSettings(true);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [scheduleTime, setScheduleTime] = useState("");
  const [orderData, setOrderData] = useState({
    customer_id: selectedCustomer?.id || "",
    stops: [],
    fields: [],
    points: false,
    tip: 0,
    vehicle_type_id: selectedVehicleType,
    commission: "",
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  useEffect(() => {
    ApiService({ method: "OPTIONS", route: "orders" })
      .then((response) => {
        console.log("gh" + response);
        if (response.status === 26) {
          openSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setFormData(response.data.data);
            orderData.fields = response.data?.data?.fields || [];
            setOrderData({ ...orderData });
          } else {
            openSnackbar(response?.data?.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(selectedVehicleType);
    orderData.vehicle_type_id = selectedVehicleType;
    orderData.customer_id = selectedCustomer?.id;
    orderData.fields = formFields.length > 0 ? formFields : fields;
    setOrderData({ ...orderData });
  }, [selectedVehicleType, formFields, selectedCustomer]);

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  function showValidations() {
    if (!orderData.vehicle_type_id) {
      openSnackbar("Choose Vehicle is requied");
      return false;
    }
    if (orderData.stops.length == 0) {
      openSnackbar("Choose Pickup address");
      return false;
    }
    if (orderData.stops.length > 0 && orderData.stops.length < 2) {
      openSnackbar("Choose Delivery address");
      return false;
    }
    if (!scheduleTime) {
      openSnackbar("Choose Schedule Time");
      return false;
    }
    if (scheduleTime) {
      orderData.stops.map((s) => {
        s.complete_after = moment(scheduleTime).format("YYYY-MM-DD HH:mm:ss");
      });
    }
    if (orderData.fields.length >= 0) {
      let validate = true;
      let m = "";
      orderData.fields.map((f) => {
        console.log(f);
        if (f.required === 1) {
          if (!f.value) {
            validate = false;
            m = f;
          }
        }
      });
      if (validate == false) {
        openSnackbar(m.title + " is required");
        return false;
      }
    }
    return true;
  }

  return (
    <div className={"mainInnerView"}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <h1 style={{ marginBottom: "10px" }}>Create Order</h1>
        <CustomerFilter
          value={selectedCustomer?.id || ""}
          style={{
            marginLeft: "auto",
            marginRight: "10px",
          }}
          handleCustomerChange={(id, value) => {
            setSelectedCustomer(value);
          }}
        />
      </div>
      {selectedCustomer?.id ? (
        <GView
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <GView
            style={{
              width: "30%",
              minWidth: "250px",
              background: "#f5f6f9",
            }}
          >
            <GView
              style={{
                height: "calc(100vh - 225px)",
                overflow: "overlay",
                padding: "5px 20px 5px 10px",
              }}
            >
              {isLoading == true ? (
                <>
                  <GLineShimmer />
                  <GRoundShimmer></GRoundShimmer>
                  <GLineShimmer />
                  <GCardShimmer
                    type={"single"}
                    color={"#DBDCDD"}
                  ></GCardShimmer>
                </>
              ) : (
                <>
                  {vehicle_types && vehicle_types.length > 0 && (
                    <VehicleSection
                      data={vehicle_types}
                      onSelect={(id) => setSelectedVehicleType(id)}
                    />
                  )}

                  <GView style={{ margin: "20px 0" }}>
                    <GText
                      g4
                      semi
                      text={"Select Stops"}
                      style={{ margin: "10px 0" }}
                    />
                    {addresses && (
                      <OrderStops setPlaces={setPlaces} address={addresses} />
                    )}
                  </GView>
                  <GView style={{ margin: "20px 0" }}>
                    <GText
                      g4
                      semi
                      text={"Schedule Time"}
                      style={{ margin: "10px 0" }}
                    />
                    <DateTimePicker>
                      <GdatePicker
                        showTimeSelect
                        id="schedule_time"
                        name="schedule_time"
                        value={scheduleTime ? new Date(scheduleTime) : null}
                        minDate={new Date()}
                        minTime={
                          new Date(
                            new Date().setHours(currentHour, currentMins, 0, 0)
                          )
                        }
                        maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                        format="MMM dd yyyy, h:mm aa"
                        onChange={(date) => {
                          console.log(date);
                          const isSelectedDateInFuture =
                            new Date().getDate() < date.getDate();
                          if (isSelectedDateInFuture) {
                            setCurrentHours(0);
                            setCurrentMins(0);
                          } else {
                            setCurrentMins(new Date().getMinutes());
                            setCurrentHours(new Date().getHours());
                          }

                          setScheduleTime(date);
                        }}
                      />
                    </DateTimePicker>
                  </GView>

                  <GView style={{ margin: "20px 0" }}>
                    <GText
                      g4
                      semi
                      text={"Commission"}
                      style={{ margin: "10px 0" }}
                    />
                    <GInput
                      type="text"
                      name={"commission"}
                      id={"commission"}
                      value={orderData?.commission}
                      display="block"
                      style={{
                        background: "#fff",
                        border: "1px solid #f2f2f2",
                        color: "#333",
                      }}
                      onChange={(e) => {
                        const re = /^[0-9.\b]+$/;
                        if (e.target.value && !re.test(e.target.value)) {
                          return;
                        } else {
                          orderData.commission = e.target.value || "";
                          setOrderData({ ...orderData });
                        }
                      }}
                    />
                  </GView>

                  {fields && fields.length > 0 && (
                    <FormSection
                      data={fields}
                      settings={settings}
                      onChange={(fields) => {
                        setFormFields(fields);
                      }}
                    />
                  )}
                </>
              )}
            </GView>
            {isLoading != true && (
              <GButton
                variant="condensed"
                // disabled={!isFormValidate}
                children="Proceed to Payment"
                style={{
                  width: "calc(100% - 20px)",
                  margin: "15px 10px 0",
                  textTransfoem: "uppercase",
                  fontSize: "14px",
                  padding: "0.8em",
                  fontWeight: "550",
                  borderRadius: "2px",
                }}
                onClick={() => {
                  let v = showValidations();
                  if (v == true) {
                    setCheckoutVisible(true);
                  }
                }}
              />
            )}
          </GView>

          <GView
            style={{
              width: "70%",
              position: "relative",
            }}
          >
            <GTaxiMap
              center={{ lat: 30.70586, lng: 76.708282 }}
              zoom={15}
              places={places}
              isAutoComplete={false}
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=" +
                process.env.REACT_APP_GOOGLE_KEY +
                "&libraries=geometry,drawing,places"
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: "calc(100vh - 250px)",
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </GView>
        </GView>
      ) : (
        <div className="listPaper">
          <GEmpty subtitle={"Choose customer to create an order"} />
        </div>
      )}
      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CheckoutForm
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{ ...orderData }}
        />
      </Modal>
    </div>
  );
};

export default OrderCreate;
