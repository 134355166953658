import React, { useState } from "react";
import { Add, Close } from "@material-ui/icons";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function CreatePricingRule(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const applyToOptions = [
    {
      key: "Flat Fare",
      value: "1",
    },
    {
      key: "Distance and Time based Fare",
      value: "2",
    },
    {
      key: "Distance Slots based Fare",
      value: "3",
    },
  ];
  const initialValues = {
    title: props.ruleData.title || "",
    rule_type: props.ruleData.rule_type || "",
    base_fare: props.ruleData.base_fare || "",
    duration_fee: props.ruleData.duration_fee || "",
    distance_fee: props.ruleData.distance_fee || "",
    minimum_fare: props.ruleData.minimum_fare || "",
    options: props.ruleData.options || [{ upto: "", cost: "" }],
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    if (values.rule_type !== "3") {
      delete values.options;
    }
    if (values.rule_type !== "2") {
      delete values.minimum_fare;
      delete values.distance_fee;
      delete values.duration_fee;
    }
    if (!(values.rule_type === "2" || values.rule_type === "1")) {
      delete values.base_fare;
    }

    var hitUrl = !props.isEmpty
      ? "task/rules/" + props.ruleData.id
      : "task/rules";
    var hitMethod = !props.isEmpty ? "PUT" : "POST";

    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setPrvisible(false);
          props.onSubmit();
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    rule_type: Yup.string().required("Required"),
    base_fare: Yup.string().when("rule_type", {
      is: (rule_type) => rule_type == "1" || rule_type == "2",
      then: Yup.string().required("Required"),
    }),
    duration_fee: Yup.string().when("rule_type", {
      is: "2",
      then: Yup.string().required("Required"),
    }),
    distance_fee: Yup.string().when("rule_type", {
      is: "2",
      then: Yup.string().required("Required"),
    }),

    options: Yup.array().when("rule_type", {
      is: "3",
      then: Yup.array()
        .of(
          Yup.object({
            upto: Yup.string().required("Required"), // these constraints take precedence
            cost: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 option"),
    }),
  });

  if (props.prvisible === false) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Pricing Rule
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Rule name"
                name="title"
              />
              <h4 style={{ margin: "15px 15px 5px" }}>Fare Calculations</h4>
              <FormikControl
                control="radio"
                label="Apply to"
                name="rule_type"
                options={applyToOptions}
                key_title="key"
                key_value="value"
              />
              {values.rule_type === "1" ? (
                <FormikControl
                  control="input"
                  type="text"
                  label="Base Fare"
                  name="base_fare"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("base_fare", event.target.value);
                    }
                  }}
                  style={{
                    display: "inline-block",
                    width: "30%",
                    verticalAlign: "top",
                  }}
                />
              ) : values.rule_type === "2" ? (
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "33%",
                      verticalAlign: "top",
                    }}
                  >
                    <FormikControl
                      control="input"
                      type="text"
                      label="Base Fare"
                      name="base_fare"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("base_fare", event.target.value);
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "33%",
                      verticalAlign: "top",
                    }}
                  >
                    <FormikControl
                      control="input"
                      type="text"
                      label="Duration Fee"
                      name="duration_fee"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("duration_fee", event.target.value);
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "33%" }}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Distance Fee"
                      name="distance_fee"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("distance_fee", event.target.value);
                        }
                      }}
                    />
                  </div>
                  <FormikControl
                    control="input"
                    type="text"
                    label=" Minimum Fare (Leave empty if there is no minimum fare)"
                    name="minimum_fare"
                    onChange={(event) => {
                      const re = /^[0-9.\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("minimum_fare", event.target.value);
                      }
                    }}
                  />
                </div>
              ) : values.rule_type === "3" ? (
                <div className="form-control">
                  <p
                    style={{
                      color: "#ccc",
                      fontSize: "14px",
                      fontStyle: "italic",
                    }}
                  >
                    (If the distance is more than the last slot, then the price
                    of last slot will be applied.)
                  </p>
                  <FieldArray name="options">
                    {(fieldArrayProps) => {
                      console.log(fieldArrayProps);
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { options } = values;
                      // if (options.length === 0) {
                      //   push({ upto: "", cost: "" });
                      // }
                      return (
                        <div>
                          <div
                            style={{
                              display: "inline-flex",
                              width: "100%",
                            }}
                            className="arrayFields"
                          >
                            <label style={{ width: "45%" }}>Upto</label>
                            <label style={{ width: "43%" }}>Cost</label>
                            <a
                              type="button"
                              href
                              //role="button"
                              variant="linkable"
                              onClick={(e) => {
                                push({ upto: "", cost: "" });
                              }}
                            >
                              <Add />
                            </a>
                          </div>
                          {options.map((o, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "inline-flex",
                                  width: "100%",
                                }}
                                className="arrayFields"
                              >
                                <FormikControl
                                  control="input"
                                  type="text"
                                  name={`options[${index}].upto`}
                                  divstyle={{ padding: "0" }}
                                  onChange={(event) => {
                                    const re = /^[0-9.\b]+$/;
                                    if (
                                      event.target.value &&
                                      !re.test(event.target.value)
                                    ) {
                                      return;
                                    } else {
                                      setFieldValue(
                                        `options[${index}].upto`,
                                        event.target.value
                                      );
                                    }
                                  }}
                                  style={{
                                    display: "inline-block",
                                    width: "90%",
                                    verticalAlign: "top",
                                  }}
                                />
                                {/* <Field
                                    type="text"
                                    placeholder=""
                                    name={`options[${index}].upto`}
                                  /> */}
                                {/* <Field
                                    type="text"
                                    placeholder=""
                                    name={`options[${index}].cost`}
                                  /> */}
                                <FormikControl
                                  control="input"
                                  type="text"
                                  name={`options[${index}].cost`}
                                  divstyle={{ padding: "0" }}
                                  style={{
                                    display: "inline-block",
                                    width: "90%",
                                    verticalAlign: "top",
                                  }}
                                  onChange={(event) => {
                                    const re = /^[0-9.\b]+$/;
                                    if (
                                      event.target.value &&
                                      !re.test(event.target.value)
                                    ) {
                                      return;
                                    } else {
                                      setFieldValue(
                                        `options[${index}].cost`,
                                        event.target.value
                                      );
                                    }
                                  }}
                                />
                                {options.length > 1 && (
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      padding: "7px",
                                    }}
                                    onClick={(e) => {
                                      if (options.length > 1) {
                                        remove(index);
                                      }
                                    }}
                                  >
                                    <Close />
                                  </a>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>
              ) : (
                ""
              )}

              <br />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.isEmpty ? "Create" : "Update"}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
