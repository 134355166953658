import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import GConfirmDialog from "./gConfirmDialog";
// import { Breadcrumbs, Typography, Link } from "@material-ui/core";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
`;
const Li = styled.li`
  background: #d3d9e5;
  color: #747d8c;
  padding: 7px 0 7px 25px;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: block;
  float: left;
  &:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 100%;
    z-index: 2;
    border-left: 13px solid #d3d9e5;
  }
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.Completed,
  &.colored {
    background: #2d4961;
    color: #fff;
  }
  &.Completed:not(:last-child):after,
  &.colored:after {
    border-left: 14px solid #2d4961;
  }
  &.Cancelled {
    background: #e88689;
    color: #fff;
  }
  &.Cancelled:not(:last-child):after {
    border-left: 14px solid #e88689;
  }
  &.Failed,
  &.Rejected {
    background: #bbb;
    color: #fff;
  }
  &.Failed:not(:last-child):after,
  &.Rejected:not(:last-child):after {
    border-left: 14px solid #bbb;
  }
`;

export default function CustomSeparator(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  console.log(props);
  // const classes = useStyles();

  function handleClick() {
    let orderStatus =
      selectedStatus === "accepted"
        ? "accept"
        : selectedStatus === "completed"
        ? "complete"
        : selectedStatus;
    props.onUpdateStatus(orderStatus);
    // event.preventDefault();
    // console.info("You clicked a breadcrumb.");
  }

  return (
    <div>
      {props.class !== "Failed" &&
      props.class !== "Cancelled" &&
      props.class !== "Rejected" ? (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "failed" &&
                status.identifier !== "cancelled" &&
                status.identifier !== "rejected"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "failed" &&
                            status.identifier !== "cancelled" &&
                            status.identifier !== "rejected"
                        ).length +
                      "%",
                    // cursor: props.class === 'Completed' ? 'auto' : s.identifier === 'pending' || s.identifier === 'processing' || s.identifier === 'cancelled' || s.title === props.class ? 'auto' : 'pointer'
                  }}
                  // onClick={handleClick}
                  key={s.identifier}
                  className={(props.class, s.color)}
                  // onClick={() => {
                  //   if (props.class === 'Completed') {
                  //     return;
                  //   }
                  //   if (s.identifier === 'pending' || s.identifier === 'processing' || s.identifier === 'cancelled' || s.title === props.class) {
                  //     return;
                  //   } setSelectedStatus(s.identifier); setDialogOpen(true)
                  // }}
                >
                  {" "}
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      ) : props.class !== "Failed" &&
        props.class !== "Completed" &&
        props.class !== "Rejected" ? (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "completed" &&
                status.identifier !== "failed" &&
                status.identifier !== "rejected"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "failed" &&
                            status.identifier !== "completed" &&
                            status.identifier !== "rejected"
                        ).length +
                      "%",
                    // cursor: props.class === 'Cancelled' || props.class === 'Failed' ? 'auto' : s.identifier === 'pending' || s.identifier === 'processing' || s.identifier === 'cancelled' || s.title === props.class ? 'auto' : 'pointer'
                  }}
                  key={s.identifier}
                  className={props.class}
                  // onClick={() => {
                  //   if (props.class === 'Cancelled' || props.class === 'Failed') {
                  //     return;
                  //   }
                  //   if (s.identifier === 'pending' || s.identifier === 'processing' || s.identifier === 'cancelled' || s.title === props.class) {
                  //     return;
                  //   } setSelectedStatus(s.identifier); setDialogOpen(true)
                  // }}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      ) : props.class !== "Cancelled" &&
        props.class !== "Completed" &&
        props.class !== "Rejected" ? (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "completed" &&
                status.identifier !== "cancelled" &&
                status.identifier !== "rejected"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "cancelled" &&
                            status.identifier !== "completed" &&
                            status.identifier !== "rejected"
                        ).length +
                      "%",
                  }}
                  key={s.identifier}
                  className={props.class}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      ) : (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "completed" &&
                status.identifier !== "cancelled" &&
                status.identifier !== "started" &&
                status.identifier !== "failed"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "cancelled" &&
                            status.identifier !== "completed" &&
                            status.identifier !== "started" &&
                            status.identifier !== "failed"
                        ).length +
                      "%",
                  }}
                  key={s.identifier}
                  className={props.class}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Update Status"
          text="Are you sure you want to update the status?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleClick();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );

  // <div className={classes.root}>
  //   <Breadcrumbs separator="›" aria-label="breadcrumb">
  //     <Link color="inherit" href="/" onClick={handleClick}>
  //       Material-UI
  //     </Link>
  //     <Link
  //       color="inherit"
  //       href="/getting-started/installation/"
  //       onClick={handleClick}
  //     >
  //       Core
  //     </Link>
  //     <Typography color="textPrimary">Breadcrumb</Typography>
  //   </Breadcrumbs>
  //   <Breadcrumbs separator="-" aria-label="breadcrumb">
  //     <Link color="inherit" href="/" onClick={handleClick}>
  //       Material-UI
  //     </Link>
  //     <Link
  //       color="inherit"
  //       href="/getting-started/installation/"
  //       onClick={handleClick}
  //     >
  //       Core
  //     </Link>
  //     <Typography color="textPrimary">Breadcrumb</Typography>
  //   </Breadcrumbs>
  //   <Breadcrumbs
  //     separator={<NavigateNextIcon fontSize="small" />}
  //     aria-label="breadcrumb"
  //   >
  //     <Link color="inherit" href="/" onClick={handleClick}>
  //       Material-UI
  //     </Link>
  //     <Link
  //       color="inherit"
  //       href="/getting-started/installation/"
  //       onClick={handleClick}
  //     >
  //       Core
  //     </Link>
  //     <Typography color="textPrimary">Breadcrumb</Typography>
  //   </Breadcrumbs>
  // </div>
}
